import React from 'react';

import DoublePaneImage from './DoublePane.png';

export const DoublePane = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={DoublePaneImage} style={{ ...styles }} />
  );
};
export default DoublePane;
