import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import { EMAIL } from "@library/common";

export const NotOperationalView = () => {
  return (
    <Stack
      height="100vh"
      bgcolor="primary.main"
      justifyContent="center"
      alignItems="center"
      spacing={5}
      p={5}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={5}
        width="50%"
      >
        <Typography color="primary.contrastText" variant="h2" fontWeight="bold">
          Pearl isn&apos;t operating in your area yet.
        </Typography>

        <Typography color="primary.contrastText" variant="body1">
          To stay in touch, send us an email to join our mailing list.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          target="_blank"
          href={`mailto:${EMAIL}?subject=${encodeURIComponent(`Keep me updated when you are operating in my service area for ${localStorage.getItem("pearl-not-operational-address")}`)}`}
        >
          Stay in touch
        </Button>
      </Stack>
    </Stack>
  );
};
export default NotOperationalView;
