import React from "react";
import type { StackProps } from "@mui/material";
import { Stack } from "@mui/material";

const AccordionContainer = (props: StackProps) => {
  return (
    <Stack
      {...props}
      sx={{
        "& .MuiAccordion-root": {
          borderBottom: "1px solid #eee",
        },
        "& .MuiAccordion-root:last-child": {
          borderBottom: "0px",
        },
      }}
    />
  );
};

export default AccordionContainer;
