import React from 'react';

import MetalImage from './Metal.png';

export const Metal = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={MetalImage} style={{ ...styles }} />
  );
};
export default Metal;
