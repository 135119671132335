import React from 'react';

import TriplePaneImage from './TriplePane.png';

export const TriplePane = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={TriplePaneImage} style={{ ...styles }} />
  );
};
export default TriplePane;
