import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["fuelSource"],
    properties: {
      fuelSource: {
        title: "Fuel Source",
        ...props.fuelSource,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 1,
    maxWidth: "600px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    fuelSource: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 6,
      md: 6,
      lg: 6,
    },
  };
};
