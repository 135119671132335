import React from "react";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import BangSVG from "@library/assets/Bang.svg";

interface SkipModalProps {
  open: boolean;
  onClose: (toSkip: boolean) => void;
  title?: string;
  description?: string;
}

const modalTheme = {
  backgroundColor: "secondary.main",
  color: "secondary.contrastText",
};
export const SkipModal: React.FC<SkipModalProps> = ({
  open,
  onClose,
  title,
  description,
}) => {
  return (
    <Dialog
      PaperProps={{
        sx: {
          ...modalTheme,
          maxWidth: "340px",
          padding: "10px",
          borderRadius: "25px",
        },
      }}
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText sx={modalTheme} id="">
          <img src={BangSVG} alt="Are you sure?" />
          <Typography
            variant="subtitle2"
            sx={{ marginBottom: "20px", fontSize: "18px" }}
          >
            {title
              ? title
              : "This information improves the accuracy of your estimate."}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ fontSize: "14px", fontWeight: 300 }}
          >
            {description
              ? description
              : "If you skip this step, we`ll need to confirm this information before we finalize your quote."}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          justifyContent: "space-between",
          padding: "0 20px 24px",
        }}
      >
        <Button
          onClick={() => onClose(false)}
          variant="outlined"
          sx={{
            backgroundColor: "rgba(255,255,255,0.2)",
            color: "secondary.contrastText",
            textTransform: "none",
            width: "150px",
            border: "none",
          }}
        >
          Go Back
        </Button>
        <Button
          onClick={() => onClose(true)}
          sx={{ ...modalTheme, textTransform: "none", width: "170px" }}
        >
          Skip Anyway
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SkipModal;
