import React from 'react';

import UnfinishedBasementImage from './UnfinishedBasement.png';

export const UnfinishedBasement = ({ styles = {} }) => {
  return (
    <img src={UnfinishedBasementImage} style={{ ...styles }} />
  );
};
export default UnfinishedBasement;
