import React, { useContext } from "react";
import { Divider, Stack, Typography, useTheme } from "@mui/material";

import { formatNumber } from "@library/common";
import { EstimateContext } from "./EstimateDetail";

const SystemMetrics = ({ filter }: { filter?: string | undefined }) => {
  const { estimate } = useContext(EstimateContext);

  const theme = useTheme();

  if (!estimate) return null;
  const data = estimate;

  const _data = {
    systemMetrics: [
      {
        title: "Capacity",
        value: `${formatNumber(data?.systemCapacity ?? "")} BTU`,
      },
      {
        title: "SEER2",
        value: formatNumber(data?.seer ?? "", { toFixed: 1 }),
      },
      {
        title: "EER2",
        value: formatNumber(data?.eer ?? "", { toFixed: 1 }),
      },
      {
        title: "HSPF2",
        value: formatNumber(data?.hspf ?? "", { toFixed: 1 }),
      },
      {
        title: "Dimensions",
        // value: [
        //   "Indoor (W × H × D):",
        //   `${data?.dimensionsIndoor ?? ""} inches`,
        //   "Outdoor (W × H × D):",
        //   `${data?.dimensionsOutdoor ?? ""} inches`,
        // ],
        divider: false,
      },
      {
        title: "Indoor (w × h × d):",
        value: `${data?.dimensionsIndoor ?? ""} in.`,
        divider: false,
      },
      {
        title: "Outdoor (w × h × d):",
        value: `${data?.dimensionsOutdoor ?? ""} in.`,
      },
      {
        title: "Sound",
        value: `${data?.sound}`,
      },
      {
        title: "Warranty",
        value: data?.warranty,
      },
    ],
  };

  return (
    <Stack spacing={1} flexGrow={1} pt={2} px={2}>
      <Typography
        variant="body1"
        p={0}
        fontSize="0.9rem"
        sx={{ fontWeight: theme.typography.fontWeightMedium }}
      >
        Technical Specifications
      </Typography>
      <Divider />
      {_data.systemMetrics.map(({ title, value, divider = true }) => {
        return (
          <>
            <Stack
              direction="row"
              alignItems="stretch"
              justifyContent="space-between"
            >
              <Stack flex={1}>
                <Typography textAlign="left" flexGrow={1} fontSize="0.8rem">
                  {title}
                </Typography>
              </Stack>
              <Stack flex={1}>
                <Typography textAlign="right" fontSize="0.8rem" sx={{ filter }}>
                  {Array.isArray(value)
                    ? value.map((val) => {
                        return <div key={val}>{val}</div>;
                      })
                    : value}
                </Typography>
              </Stack>
            </Stack>
            {divider && <Divider />}
          </>
        );
      })}
    </Stack>
  );
};

export default SystemMetrics;
