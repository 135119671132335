import React from 'react';

import CentralAirConditioningImage from './CentralAirConditioning.png';

export const CentralAirConditioning = ({ styles = {} }) => {
  return (
    <img src={CentralAirConditioningImage}style={{ ...styles }} />
  );
};
export default CentralAirConditioning;
