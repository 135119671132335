import React from "react";
import { Box, Radio, Stack, styled, Typography } from "@mui/material";

const StackStyled = styled(Stack)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.primary.contrastText,
  padding: theme.spacing(5),
  height: "100%",
}));

const Panel = (props: {
  title: string;
  desc: string;
  value: string;
  onClick: (value: string) => void;
}) => (
  <StackStyled direction="row" spacing={1}>
    <Box>
      <Radio onClick={() => props.onClick(props.value)} />
    </Box>
    <Stack spacing={1}>
      <Typography variant="subtitle1">{props.title}</Typography>
      <Typography variant="subtitle1" color="gray">
        {props.desc}
      </Typography>
    </Stack>
  </StackStyled>
);

const HowToProceed = (props: { onClick: (value: string) => void }) => {
  return (
    <Stack spacing={2} my={2}>
      <Panel
        title="I'm ready to schedule a home assessment"
        desc="During a home assessment, we'll collect the information we need to finalize your quote and savings estimate and answer any questions that you have"
        value="home_assessment"
        onClick={props.onClick}
      />

      <Panel
        title="I'd like a virtual consult first"
        desc="During a virtual consult, we'll walk you through you instant estimate, share a bit more about the process to finalize your quote, and coordinate your home assessment"
        value="consult"
        onClick={props.onClick}
      />
    </Stack>
  );
};

export default HowToProceed;
