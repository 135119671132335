import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["exteriorWalls", "interiorWalls"],
    properties: {
      exteriorWalls: {
        ...props.exteriorWalls,
        errorMessage: {
          required: "Required",
        },
      },
      interiorWalls: {
        ...props.interiorWalls,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 3,
    maxWidth: "700px",
    spacing: 2,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    borderRadius: 4,
    exteriorWalls: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      titleKey: "materials",
      backgroundColor: "#fff",
      width: ["100%", "90%", "90%"],
      title: true,
      spacing: 1,
      padding: 0,
      borderRadius: 100,
      containerBorderRadius: 4,
      xs: 4,
      sm: 1.71,
      md: 1.71,
      lg: 1.71,
      fontSize: "0.8rem",
      placement: "bottom",
    },
    interiorWalls: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      titleKey: "materials",
      backgroundColor: "#fff",
      width: ["100%", "90%", "90%"],
      title: true,
      spacing: 1,
      padding: 0,
      borderRadius: 100,
      containerBorderRadius: 4,
      xs: 4,
      sm: 1.71,
      md: 1.71,
      lg: 1.71,
      fontSize: "0.8rem",
      placement: "bottom",
    },
  };
};
