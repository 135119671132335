import { styled } from "@mui/material";

const PREFIX = "Header";

export const classes = {
  userProfileButton: `${PREFIX}-userProfileButton`,
};

export const Root = styled("div")(({ theme }) => ({
  [`& .${classes.userProfileButton}`]: {
    marginLeft: 15,
    borderRadius: 10,
    color: theme.palette.common.white,
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    padding: "6px 16px",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.2)",
    },
    "& p": {
      marginBottom: -5,
      fontWeight: "bold",
    },
  },
}));
