import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import type { AccordionProps, SxProps } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { formatCurrency } from "@library/common";
import { CostItems } from "./CostItems";

interface Metadata {
  title: string;
  expanded: boolean;
  explainerText?: string;
}

const TITLE_METADATA: Metadata[] = [
  {
    title: "HVAC Equipment / Installation",
    expanded: true,
  },
  {
    title: "Electrical",
    expanded: true,
    // explainerText:
    //   "Note: During a home assessment, we will evaluate whether you need an upgraded service panel.",
  },
  {
    title: "Weatherization",
    expanded: true,
  },
  {
    title: "Other",
    expanded: true,
    // explainerText:
    //   "Note: During a home assessment, we will confirm that the home is safe and code compliant.",
  },
  {
    title: "Rebates, Credits, and Discounts",
    expanded: true,
  },
];

const findTitleMetadata = (title: string) => {
  return TITLE_METADATA.find(
    (e) => e.title.toLowerCase() === title.toLowerCase(),
  );
};

const sumTotalItems = (items: CostSummaryItem[]) => {
  return items.reduce((acc, item) => {
    const total = item.totalPrice ?? 0;
    return acc + total;
  }, 0);
};

export interface Item {
  description: string;
  qty: number;
  unitPrice?: number | null;
  totalPrice: number | null;
}

export interface CostSummary {
  title: string;
  items: Item[];
}

export interface CostSummaryItem {
  description: string;
  qty: number;
  unitPrice?: number | null;
  totalPrice: number | null;
  metadata?: string;
}

interface CostSummaryItemAccordionProps {
  item: CostSummary;
  AccordionProps?: AccordionProps["sx"];
  backgroundColor?: string;
  color?: string;
  costItemsSx: SxProps;
  filter?: string | undefined;
}

export const CostSummaryItemAccordion = ({
  item,
  AccordionProps,
  backgroundColor,
  color,
  costItemsSx,
  filter,
}: CostSummaryItemAccordionProps) => {
  const metadata = findTitleMetadata(item.title);
  const theme = useTheme();
  let defaultExpanded = metadata?.expanded;
  if (sumTotalItems(item.items) <= 0) {
    defaultExpanded = false;
  }
  return (
    <Accordion
      sx={{
        boxShadow: "none",
        backgroundColor,
        ...AccordionProps,
      }}
      disableGutters
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        sx={{
          p: 0,
          m: 0,
          backgroundColor,
          color,
          ".MuiAccordionSummary-content": {
            m: 0,
          },
          svg: {
            color,
          },
          flexDirection: "row-reverse",
        }}
        expandIcon={
          <ExpandMoreIcon
            htmlColor={color}
            sx={{ alignSelf: "topleft", transform: "rotate(180deg)" }}
          />
        }
      >
        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: "0.9rem",
          }}
          flexGrow={1}
          mb={0}
          pl={0}
        >
          {item.title}
        </Typography>

        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
            fontSize: "0.9rem",
            filter,
          }}
        >
          {formatCurrency(sumTotalItems(item.items))}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          m: 0,
          p: 0,
          backgroundColor,
          position: "relative",
          pl: 3,
        }}
      >
        {metadata?.explainerText && (
          <Stack mb={2} sx={{ position: "relative", left: "20px" }}>
            <Typography
              variant="caption"
              sx={{ color: "action.active" }}
              fontSize={10}
            >
              {metadata?.explainerText}
            </Typography>
          </Stack>
        )}
        <Stack spacing={1} pb={3}>
          <CostItems items={item.items} sx={costItemsSx} filter={filter} />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};
