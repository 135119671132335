import React from 'react';

import WoodImage from './Wood.png';

export const Wood = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={WoodImage} style={{ ...styles }} />
  );
};
export default Wood;
