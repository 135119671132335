import React from 'react';

import BoilerWithRadiators1 from './BoilerWithRadiators1.png';
import BoilerWithRadiators2 from './BoilerWithRadiators2.png';
import { Stack } from '@mui/material';

export const BoilerWithRaditors = ({ styles = {} }) => {
  return (
    <Stack spacing={1}>
      <Stack>
        <img src={BoilerWithRadiators1} style={{ ...styles }} />
      </Stack>
      <Stack>
        <img src={BoilerWithRadiators2} style={{ ...styles }} />
      </Stack>
    </Stack>
  );
};
export default BoilerWithRaditors;
