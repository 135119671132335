import * as React from "react";
import { useContext } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box, Grid, Toolbar, CircularProgress, Alert } from "@mui/material";

import { SettingsContext } from "@library/settings/provider";
import LoginPageView from "@library/pages/LoginPageView";
import MagicLinkPageView from "@library/pages/MagicLinkPageView";
import ResetPasswordPageView from "@library/pages/ResetPasswordPageView";
import UpdatePasswordPageView from "@library/pages/UpdatePasswordPageView";
import OnboardingPageView from "@library/pages/OnboardingPageView";

import {
  LandingPageView,
  ConciergePageView,
  NotOperationalView,
} from "./pages";

// import ConciergePageViewV3 from "./pages/ConciergePageViewV3";

function Router() {
  const { isLoading, error } = useContext(SettingsContext);
  return (
    <>
      {error ? (
        <Box component={"main"}>
          <Toolbar sx={{ height: "64px" }} />{" "}
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          </Grid>
        </Box>
      ) : isLoading ? (
        <Box component={"main"}>
          <Toolbar sx={{ height: "64px" }} />{" "}
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: "100vh" }}
          >
            <Grid item xs={3}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box component={"main"}>
          <Toolbar sx={{ height: "64px" }} />{" "}
          {/* This empty Toolbar pushes content below the fixed AppBar */}
          <Routes>
            <Route path="/login" element={<LoginPageView />} />
            <Route path="/auth/login" element={<LoginPageView />} />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            <Route path="/magic-link" element={<MagicLinkPageView />} />
            <Route
              path="/auth/magic-link"
              element={<MagicLinkPageView />}
            />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            <Route path="/reset-password" element={<ResetPasswordPageView />} />
            <Route
              path="/auth/reset-password"
              element={<ResetPasswordPageView />}
            />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            <Route
              path="/update-password"
              element={<UpdatePasswordPageView />}
            />
            <Route
              path="/auth/update-password"
              element={<UpdatePasswordPageView />}
            />{" "}
            {/* @DEPRECATE SUPPORT LEGACY PATH */}
            {/* <Route
              path={"/concierge/:quoteId"}
              element={<ConciergePageViewV3 />}
            /> */}
            <Route
              path={"/concierge/:quoteId"}
              element={<ConciergePageView />}
            />
            <Route path={"/concierge"} element={<ConciergePageView />} />
            <Route path={"/onboarding"} element={<OnboardingPageView />} />
            <Route path={"/not-operational"} element={<NotOperationalView />} />
            <Route path={"/"} element={<LandingPageView />} />
            <Route path={"*"} element={<Navigate to={"/"} />} />{" "}
            {/* catch for undefined paths */}
          </Routes>
        </Box>
      )}
    </>
  );
}

export default Router;
