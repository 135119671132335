import React from 'react';

import StuccoImage from './Stucco.png';

export const Stucco = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={StuccoImage} style={{ ...styles }} />
  );
};
export default Stucco;
