import React from 'react';

import FinishedBasementImage from './FinishedBasement.png';

export const FinishedBasement = ({ styles = {} }) => {
  return (
    <img src={FinishedBasementImage} style={{ ...styles }} />
  );
};
export default FinishedBasement;
