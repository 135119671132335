import {
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import KeyIcon from "@mui/icons-material/Key";

import { TextField } from "@mui/material";

import logo from "@library/theme/multitenancy/pearl/logo.png";
import bg from "@library/theme/multitenancy/home-background.jpg";
import { SettingsContext } from "@library/settings/provider";
import supabase from "@library/api/supabase";

import GoogleSignInButton from "@library/components/GoogleSignInButton";
import { Link, useNavigate } from "react-router-dom";

const LoginForm = ({
  handleProviderSignIn,
  authError,
  setAuthError,
}: {
  handleProviderSignIn: (provider: string, token: string) => void;
  authError: string;
  setAuthError: (error: string) => void;
}) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(SettingsContext);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await supabase.auth.signInWithPassword({
      email,
      password,
    });
    setIsLoading(false);
    if (response?.error) {
      setAuthError(response?.error?.message ?? "An unknown error occured.");
    } else {
      setIsAuthenticated(true);
    }
  };

  useEffect(() => {
    if (window.location.href.includes("/login")) {
      if (!isAuthenticated) return;
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const isError = Boolean(authError);

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          {authError && <Alert severity="error">{authError}</Alert>}
          <TextField
            name="email"
            label="Email"
            defaultValue={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            InputProps={{ error: isError }}
          />
          <TextField
            name="password"
            label="Password"
            defaultValue={password}
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            InputProps={{
              type: "password",
              error: isError,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            data-testid="submit"
            disabled={isLoading}
          >
            Submit
          </Button>
          <Link to="/reset-password" style={{ margin: "5px auto" }}>
            <Button>Forgot password</Button>
          </Link>
        </Stack>
        <Divider>
          <Chip label="Or" size="small" />
        </Divider>
        <Stack alignSelf="center">
          <GoogleSignInButton onSignUp={handleProviderSignIn} />
        </Stack>
        <Divider>
          <Chip label="Or" size="small" />
        </Divider>
        <Stack alignSelf="center">
          <Link to="/magic-link" style={{ margin: "5px auto" }}>
            <Button>Request magic link</Button>
          </Link>
        </Stack>
      </Stack>
    </form>
  );
};

export const LoginPageView = () => {
  const theme = useTheme();
  const { setIsAuthenticated, setIsLoading } = useContext(SettingsContext);
  const [authError, setAuthError] = React.useState<string>("");

  const handleProviderSignIn = async (provider: string, token: string) => {
    const response = await supabase.auth.signInWithIdToken({
      provider,
      token,
    });
    if (response?.error) {
      setAuthError(response?.error?.message ?? "An unknown error occured.");
      setIsLoading(false);
    } else {
      setIsLoading(true);
      setIsAuthenticated(true);
    }
  };

  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="row"
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 1100,
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid
          item
          md={6}
          sm={0}
          xs={0}
          sx={{
            display: ["none", "none", "initial"],
            minHeight: "100vh",
            backgroundColor: "green",
            backgroundImage: `url(${bg})`,
          }}
        ></Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          sx={{ minHeight: "100vh" }}
        >
          <Stack justifyContent="center" alignItems="center" height="100vh">
            <Link to={window.location.origin}>
              <img
                alt="Pearl Logo"
                src={logo}
                style={{
                  width: "297px",
                }}
              />
            </Link>
            <Stack
              width={400}
              p={5}
              borderRadius={3}
              spacing={5}
              sx={{ backgroundColor: theme.palette.primary.contrastText }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon />
                <Typography variant="h5">Log in</Typography>
              </Stack>
              <LoginForm
                handleProviderSignIn={handleProviderSignIn}
                authError={authError}
                setAuthError={setAuthError}
              />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default LoginPageView;
