import React from 'react';

import PartiallyFinishedBasementImage from './PartiallyFinishedBasement.png';

export const PartiallyFinishedBasement = ({ styles = {} }) => {
  return (
    <img src={PartiallyFinishedBasementImage} style={{ ...styles }} />
  );
};
export default PartiallyFinishedBasement;
