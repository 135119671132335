import React from 'react';

import WindowUnitAirConditioningImage from './WindowUnitAirConditioning.png';

export const WindowUnitAirConditioning = ({ styles = {} }) => {
  return (
    <img src={WindowUnitAirConditioningImage} style={{ ...styles }} />
  );
};
export default WindowUnitAirConditioning;
