import React from "react";
import { useEffect, useMemo, useState } from "react";
import type { CircularProgressProps, LinearProgressProps } from "@mui/material";
import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";

interface WaitingFixedTimeProps {
  onDone?: () => void;
  waitTimeInSeconds?: number;
  variant: "circular" | "linear";
  steps?: number;
  progressColor?: LinearProgressProps["color"];
  doneProgressColor?: LinearProgressProps["color"];
  textColor?: string;
}
function CircularProgressWithLabel(
  props: CircularProgressProps & {
    value: number;
    text?: {
      color: string;
    };
  },
) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          color={props.text?.color}
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}
function LinearProgressWithLabel(
  props: LinearProgressProps & {
    value: number;
    text?: {
      color: string;
    };
  },
) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", width: "100%" }}
      id="linear-progress"
    >
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color={props.text?.color}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

export const WaitingFixedTime: React.FC<WaitingFixedTimeProps> = ({
  onDone,
  waitTimeInSeconds = 30,
  variant,
  steps = 10,
  progressColor = "success",
  doneProgressColor = "success",
  textColor = "primary.main",
}) => {
  const waitTimeInMs = useMemo(
    () => waitTimeInSeconds * 1000,
    [waitTimeInSeconds],
  );
  const waitTimeInterval = useMemo(
    () => waitTimeInMs / steps,
    [waitTimeInMs, steps],
  );
  const [progress, setProgress] = useState(0);
  const [done, setDone] = useState(false);

  useEffect(() => {
    const timer = setInterval(
      () => setProgress((prevProgress) => prevProgress + 100 / steps),
      waitTimeInterval,
    );

    setTimeout(() => {
      clearInterval(timer);
      setDone(true);
      onDone?.();
    }, waitTimeInMs);

    return () => {
      clearInterval(timer);
    };
  }, [waitTimeInterval, waitTimeInMs, setDone, onDone, steps]);

  if (done)
    return (
      <CircularProgressWithLabel
        variant="determinate"
        value={100}
        color={doneProgressColor}
      />
    );

  if (variant === "circular")
    return (
      <CircularProgressWithLabel
        variant="determinate"
        value={progress}
        color={progressColor}
        text={{ color: textColor }}
      />
    );

  return (
    <LinearProgressWithLabel
      variant="determinate"
      value={progress}
      color={progressColor}
      text={{ color: textColor }}
      sx={{ height: 10 }}
    />
  );
};

export default WaitingFixedTime;