import React from 'react';

import ConcreteImage from './Concrete.png';

export const Concrete = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={ConcreteImage} style={{ ...styles }} />
  );
};
export default Concrete;
