import React from 'react';

import CeramicTileImage from './CeramicTile.png';

export const CeramicTile = ({ styles = {} }) => {
  return (
    <img src={CeramicTileImage} style={{ ...styles }} />
  );
};
export default CeramicTile;
