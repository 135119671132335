export const schema = () => {
  return {
    type: "object",
    properties: {},
  };
};

export const uiSchema = () => {
  return {};
};
