import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["coolingEquipmentType"],
    properties: {
      coolingEquipmentType: {
        ...props.coolingEquipmentType,
        minItems: 1,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 3,
    maxWidth: "800px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    coolingEquipmentType: {
      "ui:widget": "CheckboxImages",
      "ui:autocomplete": "off",
      xs: 6,
      sm: 3,
      md: 3,
      lg: 3,
    },
  };
};
