import React from 'react';

import FinishedAtticImage from './FinishedAttic.png';

export const FinishedAttic = ({ styles = {} }) => {
  return (
    <img src={FinishedAtticImage} style={{ ...styles }} />
  );
};
export default FinishedAttic;
