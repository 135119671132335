import React from "react";
import isNumber from "lodash/isNumber";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Stack, Typography, useTheme } from "@mui/material";
import { EstimateData } from "@library/domain/estimate";
import { formatCurrency, formatNumber } from "@library/common";

interface EstimateOptionsProps {
  value: EstimateData;
  topTitle: string;
  subTitle: string;
  recommended?: boolean | undefined | null;
  selected?: boolean;
  visible: boolean;
  filter: string | undefined;
}

const EstimateOption = ({
  topTitle,
  subTitle,
  value,
  recommended = false,
  selected = false,
  visible = true,
  filter,
}: EstimateOptionsProps) => {
  const theme = useTheme();
  const { upFrontCostTotal, billImpactEstimate, climateImpactEstimate } = value;
  if (!visible) return null;
  return (
    <Stack
      borderRadius="25px"
      py={selected ? 0.5 : 0}
      px={selected ? 0.5 : 0}
      spacing={0.5}
      flex={1}
      sx={{
        backgroundColor: selected
          ? `${theme.palette.primary.main}`
          : theme.palette.primary.contrastText,
        cursor: "pointer",
        position: "relative",
        top: selected ? "-7px" : "",
        border: selected ? "" : "1px solid",
        borderColor: "action.active",
        maxWidth: ["900px", "900px", "300px"],
      }}
    >
      <Stack
        margin={2}
        py={2}
        px={2}
        spacing={1}
        flex={1}
        sx={{
          borderTopLeftRadius: "25px",
          borderTopRightRadius: "25px",
          backgroundColor: selected
            ? `${theme.palette.primary.main}`
            : theme.palette.primary.contrastText,
          borderBottom: selected ? "" : "1px solid",
          borderColor: "action.active",
        }}
      >
        {recommended ? (
          <Typography
            color={
              selected ? theme.palette.warning.main : theme.palette.primary.main
            }
            fontSize="0.8rem"
          >
            Our Recommendation
          </Typography>
        ) : null}
        <Typography
          color={selected ? theme.palette.primary.contrastText : "primary.main"}
          lineHeight="1"
          style={{
            fontSize: "1.8rem",
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {topTitle}
        </Typography>
        <Typography
          color={selected ? theme.palette.primary.contrastText : "primary.main"}
          variant="subtitle2"
          maxWidth={250}
          fontSize="0.8rem"
          sx={{
            fontWeight: theme.typography.fontWeightMedium,
          }}
        >
          {subTitle}
        </Typography>
      </Stack>
      <Stack
        sx={{
          borderBottomLeftRadius: selected ? "20px" : "25px",
          borderBottomRightRadius: selected ? "20px" : "25px",
        }}
        margin={2}
        marginBottom={3}
        py={2}
        px={2}
        pb={selected ? 4 : 2}
        spacing={1}
        flex={1}
        style={{
          backgroundColor: theme.palette.primary.contrastText,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="body1"
            align="left"
            fontSize="0.8rem"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Up-Front Cost
          </Typography>
          <Typography
            variant="body1"
            align="right"
            fontSize="0.8rem"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              filter,
            }}
          >
            {formatCurrency(upFrontCostTotal, { increaseDecrease: false })}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="body1"
            align="left"
            fontSize="0.8rem"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Bill Impact
          </Typography>
          <Typography
            variant="body1"
            align="right"
            fontSize="0.8rem"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              filter,
            }}
          >
            {isNumber(billImpactEstimate)
              ? formatCurrency(billImpactEstimate, {
                  increaseDecrease: true,
                  empty: true,
                }) || "$0"
              : "TBD"}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Typography
            variant="body1"
            align="left"
            fontSize="0.8rem"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
            }}
          >
            Climate Impact
          </Typography>
          <Typography
            variant="body1"
            align="right"
            fontSize="0.8rem"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              filter,
            }}
          >
            {isNumber(climateImpactEstimate)
              ? formatNumber(climateImpactEstimate, {
                  units: " MT",
                  increaseDecrease: true,
                }) || "0 MT"
              : "TBD"}
          </Typography>
        </Stack>
      </Stack>
      {selected ? (
        <Stack
          borderRadius="0"
          margin={0}
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          spacing={1}
          style={{
            bottom: "0px",
            width: "100%",
            height: "50px",
            position: "absolute",
          }}
        >
          <Stack
            borderRadius="25px"
            margin={0}
            textAlign="center"
            style={{
              backgroundColor: theme.palette.primary.main,
              bottom: "-25px",
              left: "-5px",
              height: "50px",
              width: "50px",
              position: "relative",
            }}
          >
            <ArrowBackIosIcon
              sx={{
                color: theme.palette.primary.contrastText,
                transform: "rotate(-90deg)",
                left: "13px",
                top: "8px",
                position: "relative",
              }}
            />
          </Stack>
        </Stack>
      ) : null}
    </Stack>
  );
};

export default EstimateOption;
