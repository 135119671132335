import React, { useContext } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import get from "lodash/get";

import CurrentDistributionTypeDuctwork from "@library/assets/CONCIERGE2-CurrentDistributionTypeDuctwork.png";
import CurrentDistributionTypeElectricResistive from "@library/assets/CONCIERGE2-CurrentDistributionTypeElectricResistive.png";
import CurrentDistributionTypeHeatPump from "@library/assets/CONCIERGE2-CurrentDistributionTypeHeatPump.png";
import CurrentDistributionTypeRadiator from "@library/assets/CONCIERGE2-CurrentDistributionTypeRadiator.png";
import CurrentSystemTypeBoiler from "@library/assets/CONCIERGE2-CurrentSystemTypeBoiler.jpg";
import CurrentSystemTypeElectricResistive from "@library/assets/CONCIERGE2-CurrentSystemTypeElectricResistive.jpg";
import CurrentSystemTypeFurnace from "@library/assets/CONCIERGE2-CurrentSystemTypeFurnace.png";
import CurrentSystemTypeHeatPump from "@library/assets/CONCIERGE2-CurrentSystemTypeHeatPump.jpg";
import NewDistributionTypeDuctless from "@library/assets/CONCIERGE2-NewDistributionTypeDuctless.png";
import NewDistributionTypeDuctwork from "@library/assets/CONCIERGE2-NewDistributionTypeDuctwork.png";
import NewDistributionTypeHeatPump from "@library/assets/CONCIERGE2-NewDistributionTypeHeatPump.png";
import NewSystemTypeFurnace from "@library/assets/CONCIERGE2-NewSystemTypeFurnace.png";
import NewSystemTypeHeatPump from "@library/assets/CONCIERGE2-NewSystemTypeHeatPump.jpg";
import { EstimateContext } from "./EstimateDetail";

interface IMAGES {
  currentDistributionType: {
    [str in string]: string;
  };
  currentSystemType: {
    [str in string]: string;
  };
  newDistributionType: {
    [str in string]: string;
  };
  newSystemType: {
    [str in string]: string;
  };
}

const IMAGES: IMAGES = {
  currentDistributionType: {
    Ductwork: CurrentDistributionTypeDuctwork,
    "Electric Resistive": CurrentDistributionTypeElectricResistive,
    "Air-Source Heat Pump": CurrentDistributionTypeHeatPump,
    "Heat Pump / Mini-Split": CurrentDistributionTypeHeatPump,
    "Mini-Split": CurrentDistributionTypeHeatPump,
    Radiator: CurrentDistributionTypeRadiator,
  },
  currentSystemType: {
    Boiler: CurrentSystemTypeBoiler,
    "Electric Resistive": CurrentSystemTypeElectricResistive,
    Furnace: CurrentSystemTypeFurnace,
    "Air-Source Heat Pump": CurrentSystemTypeHeatPump,
  },
  newDistributionType: {
    Ductless: NewDistributionTypeDuctless,
    Ductwork: NewDistributionTypeDuctwork,
    "Mini-Split": NewDistributionTypeHeatPump,
  },
  newSystemType: {
    Furnace: NewSystemTypeFurnace,
    "Air-Source Heat Pump": NewSystemTypeHeatPump,
    "Heat Pump / Mini-Split": NewSystemTypeHeatPump,
    "Electric Resistive": CurrentSystemTypeElectricResistive,
    Boiler: CurrentSystemTypeBoiler,
  },
};

const parseList = (str: string): string => {
  const strArr = str.split(",");
  if (strArr.length) {
    return strArr[0] ?? "";
  }
  return "";
};

const SystemPhoto = () => {
  const { estimate } = useContext(EstimateContext);
  const theme = useTheme();

  if (!estimate) return null;

  const data = estimate;

  let currentSystemPhoto1 = null;
  let currentSystemPhoto2 = null;
  let newSystemPhoto1 = null;
  let newSystemPhoto2 = null;

  if (estimate) {
    if (data.currentSystemTypeHeat) {
      currentSystemPhoto1 = get(
        IMAGES.currentSystemType,
        parseList(data.currentSystemTypeHeat),
      );
    } else if (data.currentSystemTypeAC) {
      currentSystemPhoto1 = get(
        IMAGES.currentSystemType,
        parseList(data.currentSystemTypeAC),
      );
    }
    if (data.currentDistribution) {
      currentSystemPhoto2 = get(
        IMAGES.currentDistributionType,
        parseList(data.currentDistribution),
      );
    }
    if (data.newSystemType) {
      newSystemPhoto1 = get(
        IMAGES.newSystemType,
        parseList(data.newSystemType),
      );
    }
    if (data.newDistribution) {
      newSystemPhoto2 = get(
        IMAGES.newDistributionType,
        parseList(data.newDistribution),
      );
    }
  }

  return (
    <Stack mb={4}>
      <Typography
        color="primary"
        mb={1}
        sx={{
          fontSize: "1.0rem",
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Your System Design
      </Typography>

      <Stack direction="row" spacing={2}>
        {/* OLD SYSTEM */}
        <Stack
          borderRadius="25px"
          direction="column"
          flex={1}
          sx={{
            backgroundColor: "action.active",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.contrastText,
              position: "relative",
              overflow: "hidden",
              height: "200px",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
            }}
          >
            {currentSystemPhoto1 ? (
              <img
                src={currentSystemPhoto1}
                alt="Current System Heat and AC"
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "cover",
                }}
              />
            ) : (
              <>
                <Typography sx={{ color: "common.white" }}>
                  {data?.currentSystemTypeHeat}
                </Typography>
                <Typography sx={{ color: "common.white" }}>
                  {data?.currentSystemTypeAC}
                </Typography>
              </>
            )}
            <div
              style={{
                position: "absolute",
                top: "15px",
                left: "15px",
                zIndex: "99",
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: "15px",
                padding: "5px 10px",
              }}
            >
              <Typography
                variant="body1"
                fontSize="0.7rem"
                sx={{ color: "initial" }}
              >
                Old System
              </Typography>
            </div>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
              overflow: "hidden",
              height: "200px",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            {currentSystemPhoto2 ? (
              <img
                src={currentSystemPhoto2}
                alt="Current System Distribution"
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "cover",
                }}
              />
            ) : (
              <>
                <Typography sx={{ color: "common.white" }}>
                  {data?.currentDistribution}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>

        {/* NEW SYSTEM */}
        <Stack
          borderRadius="25px"
          direction="column"
          flex={1}
          sx={{
            backgroundColor: "action.active",
          }}
        >
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              borderBottom: "1px solid",
              borderColor: theme.palette.primary.contrastText,
              position: "relative",
              overflow: "hidden",
              height: "200px",
              borderTopLeftRadius: "25px",
              borderTopRightRadius: "25px",
            }}
          >
            {newSystemPhoto1 ? (
              <img
                src={newSystemPhoto1}
                alt="New System Heat and AC"
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "cover",
                }}
              />
            ) : (
              <>
                <Typography sx={{ color: "common.white" }}>
                  {data?.newSystemType}
                </Typography>
              </>
            )}
            <div
              style={{
                position: "absolute",
                top: "15px",
                left: "15px",
                zIndex: "99",
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: "15px",
                padding: "5px 10px",
              }}
            >
              <Typography
                variant="body1"
                fontSize="0.7rem"
                sx={{ color: "initial" }}
              >
                New System
              </Typography>
            </div>
          </Stack>
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              position: "relative",
              overflow: "hidden",
              height: "200px",
              borderBottomLeftRadius: "25px",
              borderBottomRightRadius: "25px",
            }}
          >
            {newSystemPhoto2 ? (
              <img
                src={newSystemPhoto2}
                alt="New System Distribution"
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "cover",
                }}
              />
            ) : (
              <>
                <Typography sx={{ color: "common.white" }}>
                  {data?.newDistribution}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SystemPhoto;
