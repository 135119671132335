import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";
import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["homeQuality"],
    properties: {
      homeQuality: {
        ...props.homeQuality,
        minItems: 1,
      },
    },
    dependencies: {
      homeQuality: {
        oneOf: [
          {
            properties: {
              homeQuality: {
                ...props.homeQuality,
                contains: {
                  oneOf: [
                    { const: "no_always_cold" },
                    { const: "no_always_hot" },
                    { const: "uneven" },
                  ],
                },
              },
              homeQualityMoreInfo: {
                ...props.homeQualityMoreInfo,
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 5,
    maxWidth: "500px",
    homeQuality: {
      "ui:widget": "CheckboxImages",
      "ui:autocomplete": "off",
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
    homeQualityMoreInfo: {
      "ui:autocomplete": "off",
      width: "100%",
      padding: 0,
      pt: 1,
      multiline: true,
      paddingLeft: 1,
      paddingRight: 1,
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  };
};
