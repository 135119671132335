import axios, { AxiosResponse } from "axios";

export class ForbiddenError extends Error {}

interface Params {
  [key: string]: string | number | null | undefined;
}

interface Data {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

interface Headers {
  [key: string]: string;
}

const methods = {
  get: "",
  put: "",
  post: "",
  delete: "",
};

export default class API {
  formatQuery(endpoint: string, params: Params) {
    let path = `${import.meta.env.VITE_NODE_API_URL}/${endpoint}`;
    if (params.id) {
      path += `/${params.id}`;
    }
    delete params.id;
    const uri = Object.keys(params).reduce(
      (a, v) => `${a}${a === "" ? "?" : "&"}${v}=${params[v]}`,
      ""
    );
    return `${path}/${uri}`;
  }

  async get(endpoint: string, params: Params = {}) {
    const headers = {
      "Content-Type": "application/json",
      "X-Pearl-Edison": "true",
    } as Headers;
    const pearlToken = localStorage.getItem("pearlToken");
    const supabaseToken = localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
    );
    if (pearlToken) {
      headers.Authorization = `Bearer ${pearlToken}`;
    } else if (supabaseToken) {
      try {
        const token = JSON.parse(supabaseToken);
        headers.Authorization = `Bearer ${token.access_token}`;
      } catch (e) {
        // do nothing
      }
    }
    const res = (await axios.get(this.formatQuery(endpoint, params), {
      withCredentials: true,
      headers,
    })) as AxiosResponse;

    return res.data;
  }

  async mutate(
    method: string,
    endpoint: string,
    data: Data = {},
    params: Params = {},
    _headers: Headers = {}
  ) {
    const headers = {
      "Content-Type": "application/json",
      "X-Pearl-Edison": "true",
      ..._headers,
    } as Headers;
    const pearlToken = localStorage.getItem("pearlToken");
    const supabaseToken = localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
    );
    if (pearlToken) {
      headers.Authorization = `Bearer ${pearlToken}`;
    } else if (supabaseToken) {
      try {
        const token = JSON.parse(supabaseToken);
        headers.Authorization = `Bearer ${token.access_token}`;
      } catch (e) {
        // do nothing
      }
    }

    const res = (await axios[method as keyof typeof methods](
      this.formatQuery(endpoint, params),
      method === "delete" ? { headers } : data,
      {
        withCredentials: true,
        headers,
      }
    )) as AxiosResponse;

    return res.data;
  }

  async put(
    endpoint: string,
    data: Data = {},
    params: Params = {},
    headers: Headers = {}
  ) {
    return await this.mutate("put", endpoint, data, params, headers);
  }

  async post(
    endpoint: string,
    data: Data = {},
    params: Params = {},
    headers: Headers = {}
  ) {
    return await this.mutate("post", endpoint, data, params, headers);
  }

  async delete(endpoint: string, params: Params = {}, headers: Headers = {}) {
    return await this.mutate("delete", endpoint, {}, params, headers);
  }
}
