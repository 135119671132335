import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import { TopLink } from "..";

export default [
  {
    label: "Visit DTEEnergy.com",
    href: "https://www.dteenergy.com/",
    target: "_blank",
    Icon: ArrowOutwardIcon,
  },
] as TopLink[];
