import React from 'react';
import { useTheme } from "@mui/material";

export const Smaller: React.FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M120.5 122.5V86.2897L109 79.5L97.5 86.2897V122.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76 54.5L78.6057 45.5H139.394L142 54.5H76Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.1328 89.5H64.1328L67.6071 80.5H100.87"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M123.869 89.5H153.869L150.395 80.5H117.132"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.5 115.5V98.5H78.5V115.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.5 107L78.5 107"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.5 115.5V98.5H129.5V115.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139.5 107L129.5 107"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 90.5V123.5H69V90.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M139 55.5V80.5M79 80.5V55.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163 123.5H55"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M102.627 121.171V104.873C102.627 101.353 105.48 98.5 108.999 98.5C112.519 98.5 115.372 101.353 115.372 104.873V121.171"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="106.049" cy="112.5" r="1" fill="white" />
      <path
        d="M109 60.5V72.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115 66.5L103 66.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="109"
        cy="66.5"
        r="6.75"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
      />
    </svg>
  );
};
export default Smaller;
