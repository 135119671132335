import { responsiveFontSizes } from "@mui/material";

import type { TenantName } from "./multitenancy";
import { AvailableTenants } from "./multitenancy";
import { Channel } from "@library/domain/channel";

export const getTheme = (tenant: TenantName = "pearl", channel: Channel) => {
  let tenantConfig = AvailableTenants.pearl(channel);
  let config = responsiveFontSizes(tenantConfig.theme);
  try {
    tenantConfig =
      AvailableTenants[tenant](channel) ?? AvailableTenants.pearl(channel);
    config = responsiveFontSizes(tenantConfig.theme);
    if (channel?.logoUrl) {
      config.logo.imageSrc = channel.logoUrl;
    } else if (channel?.LogoImage?.url) {
      config.logo.imageSrc = channel.LogoImage.url;
    }
  } catch (e) {
    // do nothing
  }
  return config;
};
