import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["ownOrRent"],
    properties: {
      ownOrRent: {
        ...props.ownOrRent,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    ownOrRent: {
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
      width: ["80%", "25%"],
      xs: 12,
      sm: 12,
      md: 12,
      lg: 12,
    },
  };
};
