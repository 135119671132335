import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";
import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["temperatureSummer", "temperatureWinter"],
    properties: {
      temperatureWinter: {
        ...props.temperatureWinter,
        title: "Winter",
      },
      temperatureSummer: {
        ...props.temperatureSummer,
        title: "Summer",
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 0,
    mb: 0,
    p: 0,
    px: 2,
    xs: 12,
    sm: 4,
    pt: 4,
    pb: 2,
    temperatureSummer: {
      "ui:autocomplete": "off",
      "ui:options": {
        updown: true,
        number: true,
        label: false,
      },
      // xs: 4,
      width: "160px",
      type: "number",
      endAdornment: "°F",
      fontSize: "2.0rem",
      labelFontSize: "1.5rem",
    },
    temperatureWinter: {
      "ui:autocomplete": "off",
      // xs: 4,
      width: "160px",
      type: "number",
      endAdornment: "°F",
      fontSize: "2.0rem",
      labelFontSize: "1.5rem",
    },
  };
};
