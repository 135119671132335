import React from 'react';

import UnfinishedAtticImage from './UnfinishedAttic.png';

export const UnfinishedAttic = ({ styles = {} }) => {
  return (
    <img src={UnfinishedAtticImage} style={{ ...styles }} />
  );
};
export default UnfinishedAttic;
