import React from 'react';

import Electric1 from './Electric1.png';
import Electric2 from './Electric2.png';
import { Stack } from '@mui/material';

export const Electric = ({ styles = {} }) => {
  return (
    <Stack spacing={1}>
      <Stack>
        <img src={Electric2} style={{ ...styles }} />
      </Stack>
      <Stack>
        <img src={Electric1} style={{ ...styles }} />
      </Stack>
    </Stack>
  );
};
export default Electric;
