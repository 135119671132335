import React, { memo, useState } from "react";

interface GoogleSignInButtonProps {
  onSignUp: (provider: string, token: string, email: string) => void;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    google: any;
  }
}

const useGoogleRenderButton = (
  handleSignInWithGoogle: (response: { credential: string }) => void,
) => {
  const [googleButtonRendered, setGoogleButtonRendered] = useState(false);

  const render = () => {
    if (window.google) {
      window.google.accounts.id.renderButton(
        document.getElementById("google-login-btn"),
        {
          type: "standard",
          theme: "filled_blue",
          size: "large",
          text: "signup_with",
          width: "200",
          shape: "pill",
        },
      );
    }
  };

  React.useEffect(() => {
    render();
    if (googleButtonRendered) return;
    // We check every 300ms to see if google client is loaded
    const interval = setInterval(() => {
      if (window.google) {
        clearInterval(interval);
        window.google.accounts.id.initialize({
          client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID, // Your client ID from Google Cloud
          callback: handleSignInWithGoogle, // Handler to process login token
        });
        setGoogleButtonRendered(true);
        render();
      }
    }, 300);
  }, [handleSignInWithGoogle, setGoogleButtonRendered, googleButtonRendered]);
};

function decodeJwtResponse(token: string) {
  const base64Url = token.split(".")[1]!;
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join(""),
  );

  return JSON.parse(jsonPayload) as Record<string, string>;
}

const GoogleSignInButton: React.FC<GoogleSignInButtonProps> = memo(
  ({ onSignUp }) => {
    function handleSignInWithGoogle(response: { credential: string }) {
      const decoded = decodeJwtResponse(response.credential);
      onSignUp("google", response.credential, decoded.email!);
    }

    useGoogleRenderButton(handleSignInWithGoogle);

    return <div id="google-login-btn"></div>;
  },
);

export default GoogleSignInButton;
