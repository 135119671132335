import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["rooms"],
    properties: {
      rooms: {
        ...props.rooms,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 5,
    mb: 3,
    maxWidth: "800px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    rooms: {
      "ui:widget": "ComfortGrid",
      "ui:autocomplete": "off",
      currently: "isCurrentlyAirConditioned",
      issue: "isAirConditionComfortIssue",
      info: "airConditionComfortIssueInfo",
    },
  };
};
