import React from "react";

export const DiningRoom = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7041 23.8264C20.7041 24.9175 21.5886 25.8021 22.6797 25.8021C23.7709 25.8021 24.6554 24.9175 24.6554 23.8264C24.6554 22.7353 23.7709 21.8508 22.6797 21.8508C21.5886 21.8508 20.7041 22.7353 20.7041 23.8264Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.9942 41.3916V40.3137C36.9942 32.4083 30.5856 25.9998 22.6802 25.9998C14.7748 25.9998 8.36621 32.4083 8.36621 40.3137V41.3916"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.243 36.5702C32.244 32.9059 29.2062 30.0824 25.4238 29.3943"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5199 41.425H7.83984V44.84H37.5199V41.425Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.2007 18.9436C50.2007 18.9436 56.7808 33.3858 45.827 33.3858C34.3705 33.3858 41.3903 18.9436 41.3903 18.9436H50.2007Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M39.832 23.4329C40.7161 23.1164 41.5017 22.7566 43.1186 22.7566C46.0009 22.7566 46.2407 23.9029 49.124 23.9029C50.2406 23.9029 50.9612 23.731 51.5907 23.52"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.3789 44.8401H49.0434"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M45.7109 33.5168V44.6262"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DiningRoom;
