import React from 'react';

import BrickImage from './Brick.png';

export const Brick = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={BrickImage} style={{ ...styles }} />
  );
};
export default Brick;
