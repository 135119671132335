import React from "react";
import { Stack, Typography, useTheme } from "@mui/material";
// import { SlideComponent } from "../..";

import Bolt from "@library/components/icons/Bolt";
import { PHONE_NUMBER, PHONE_NUMBER_TEL } from "@library/common";

export const Callout = () => {
  const theme = useTheme();
  return (
    <>
      <Stack
        spacing={0}
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Stack
          bgcolor={theme.palette.secondary.main}
          width="auto"
          p={[4, 4, 4]}
          spacing={0}
          mb={2}
          mt={3}
          sx={{ marginRight: 2 }}
          borderRadius="25px"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Stack spacing={2} alignItems="flex-start" sx={{ maxWidth: 300 }}>
            <Bolt />
            <Typography
              color="common.white"
              sx={{
                fontSize: ["1.4rem", "1.6rem", "1.6rem"],
                fontWeight: "bold",
              }}
            >
              Broken equipment?
            </Typography>
            <Typography color="common.white">
              Please contact us to see how we may help.
            </Typography>
            <Typography
              color="common.white"
              sx={{ fontSize: ["1.2rem", "1.6rem", "1.6rem"] }}
            >
              <a
                href={`tel:+${PHONE_NUMBER_TEL}`}
                style={{
                  color: theme.palette.secondary.contrastText,
                  textDecoration: "none",
                }}
              >
                Call {PHONE_NUMBER}
              </a>
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
