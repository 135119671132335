import React from 'react';
import { useTheme } from "@mui/material";

export const Same: React.FC = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M65 59L68.4743 37.5H149.526L153 59H65Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138 115.5H128V102.013C128 99.5205 130.239 97.5 133 97.5C135.761 97.5 138 99.5205 138 102.013V115.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90 115.5H80V102.013C80 99.5205 82.2387 97.5 85.0001 97.5C87.7615 97.5 90 99.5205 90 102.013V115.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138 82.5H128V69.013C128 66.5205 130.239 64.5 133 64.5C135.761 64.5 138 66.5205 138 69.013V82.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M114 82.5H104V69.013C104 66.5205 106.239 64.5 109 64.5C111.761 64.5 114 66.5205 114 69.013V82.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90 82.5H80V69.013C80 66.5205 82.2387 64.5 85.0001 64.5C87.7615 64.5 90 66.5205 90 69.013V82.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149 60.5V123.5H69V60.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163 123.5H55"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.1"
        d="M149 90.5H69"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="1 2"
      />
      <path
        d="M102.627 121.171V104.873C102.627 101.353 105.48 98.5 108.999 98.5C112.519 98.5 115.372 101.353 115.372 104.873V121.171"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="106.049" cy="112.5" r="1" fill="white" />
    </svg>
  );
};
export default Same;
