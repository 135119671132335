import React, { ReactElement } from "react";
import { FieldErrorProps } from "@rjsf/utils";
import { Alert, Stack } from "@mui/material";

const formatError = (error: string | ReactElement) => {
  let formattedError = String(error);
  if (formattedError.toLowerCase().includes("one of the allowed values")) {
    formattedError = "Please make a selection";
  } else if (formattedError.toLowerCase().includes("must not have fewer")) {
    formattedError = "Please make a selection";
  }
  formattedError = formattedError.replace(">=", "greater than or equal to");
  formattedError = formattedError.replace("<=", "less than or equal to");
  formattedError = formattedError.replace(">", "greater than");
  formattedError = formattedError.replace("<", "less than");
  formattedError = `${formattedError.substr(0, 1).toUpperCase()}${formattedError.substr(1)}.`;
  return formattedError;
};

export const FieldErrorTemplate = (props: FieldErrorProps) => {
  const { errors = [] } = props;
  return (
    <Stack>
      {errors.map((error: string | ReactElement, i: number) => {
        const formattedError = formatError(error);
        return (
          <Alert severity="error" key={i} className="error">
            {formattedError}
          </Alert>
        );
      })}
    </Stack>
  );
};

export default FieldErrorTemplate;
