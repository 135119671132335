import { OnboardingPayload } from "@library/domain/onboarding";
import { User } from "@library/domain/user";
import { OnNextReturn } from "../../slides";

export const visible = ({ payload }: { payload: OnboardingPayload }) => {
  if (payload.attic === "yes") return true;
  return false;
};

export const onNext = async ({
  payload,
  setPayload,
}: {
  index: number;
  payload: Partial<OnboardingPayload>;
  setPayload: (value: Partial<OnboardingPayload>) => void;
  user: Partial<User>;
  setUser: (data: User) => void;
  setIsAuthenticated: (value: boolean) => void;
}): Promise<OnNextReturn> => {
  if (payload.atticType === "no_attic") {
    payload.attic = "no";
  }
  setPayload(payload);
  return {};
};
