import { getLanguage } from "@library/theme/multitenancy";
import { Theme } from "@mui/material";

export const visible = ({ theme }: { theme: Theme }) => {
  if (
    !theme.config?.language?.[getLanguage(theme)]?.step?.[
      "CommunicationPreferencesStep"
    ]?.title
  ) {
    return false;
  }
  return true;
};
