import React from "react";

export const LaundryRoom = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93359 22.2725H53.1336"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5428 22.0979V20.6898C26.5428 19.1737 25.3137 17.9446 23.7976 17.9446H12.878C11.3619 17.9446 10.1328 19.1737 10.1328 20.6898V22.0979"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.5428 16.6575V16.5368C26.5428 15.0206 25.3137 13.7915 23.7976 13.7915H12.878C11.3619 13.7915 10.1328 15.0206 10.1328 16.5368V16.6575C10.1328 17.3684 10.7092 17.9446 11.4201 17.9446H25.2555C25.9665 17.9446 26.5428 17.3684 26.5428 16.6575Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0332 15.6685V45.7585"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.3994 15.6685L30.0336 15.6685"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M53.3994 15.6685V46.2084"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.59961 22.2085V46.2085H53.3996"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3346 39.7464C21.3154 39.7464 23.7318 37.33 23.7318 34.3492C23.7318 31.3685 21.3154 28.9521 18.3346 28.9521C15.3539 28.9521 12.9375 31.3685 12.9375 34.3492C12.9375 37.33 15.3539 39.7464 18.3346 39.7464Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7704 43.1616C24.6375 42.368 27.9397 37.7792 27.1461 32.9121C26.3525 28.0451 21.7636 24.7429 16.8966 25.5365C12.0295 26.3301 8.72731 30.9189 9.5209 35.786C10.3145 40.653 14.9033 43.9552 19.7704 43.1616Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.0098 35.0896C13.0098 35.0896 15.4651 33.139 18.8061 34.3726C22.147 35.6062 23.6656 34.6496 23.6656 34.6496"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.7341 39.7464C44.7148 39.7464 47.1312 37.33 47.1312 34.3492C47.1312 31.3685 44.7148 28.9521 41.7341 28.9521C38.7533 28.9521 36.3369 31.3685 36.3369 34.3492C36.3369 37.33 38.7533 39.7464 41.7341 39.7464Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.6143 20.3538C35.4276 20.3538 36.087 19.6944 36.087 18.8811C36.087 18.0678 35.4276 17.4084 34.6143 17.4084C33.8009 17.4084 33.1416 18.0678 33.1416 18.8811C33.1416 19.6944 33.8009 20.3538 34.6143 20.3538Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1708 43.1616C48.0379 42.368 51.3401 37.7792 50.5465 32.9121C49.7529 28.0451 45.164 24.7429 40.297 25.5365C35.4299 26.3301 32.1277 30.9189 32.9213 35.786C33.7149 40.653 38.3038 43.9552 43.1708 43.1616Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.1582 33.3723V35.3261"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LaundryRoom;
