import React, { useContext } from "react";
import { Stack, Typography, useTheme } from "@mui/material";

import Logos from "@library/components/Logos";

import { EstimateContext } from "./EstimateDetail";

const SystemInfo = () => {
  const { estimate } = useContext(EstimateContext);
  const theme = useTheme();
  const showBrands = theme.config?.showBrands;

  if (!estimate) return null;

  const data = estimate;

  let equipmentBrand = data?.equipmentBrand ?? "";
  equipmentBrand = equipmentBrand.toLowerCase();
  const brandLogo = Logos({ brand: equipmentBrand }) ?? <></>;

  return (
    <Stack px={0}>
      <Typography
        variant="body1"
        pt={3}
        pb={1}
        px={2}
        fontSize="0.9rem"
        sx={{
          fontWeight: theme.typography.fontWeightMedium,
        }}
      >
        Your Equipment
      </Typography>
      <Stack spacing={1} direction="row" alignItems="stretch">
        <Stack spacing={1} flex={2} alignItems="stretch" flexGrow={2}>
          <Stack
            direction="row"
            spacing={2}
            p={2}
            sx={{
              borderRadius: "25px",
              border: "1px solid",
              borderColor: "action.active",
            }}
            alignItems="center"
            flexGrow={1}
          >
            <Stack flex={1} justifyContent="left">
              <Typography variant="body1">Outdoor</Typography>
            </Stack>
            <Stack direction="column" flex={2}>
              <Typography variant="body1" fontSize="0.8rem">
                {data?.outdoorUnit1}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            p={2}
            sx={{
              borderRadius: "25px",
              border: "1px solid",
              borderColor: "action.active",
            }}
            alignItems="center"
            flexGrow={1}
          >
            <Stack flex={1} justifyContent={"left"}>
              <Typography variant="body1">Indoor</Typography>
            </Stack>
            <Stack direction="column" flex={2}>
              <Typography variant="body1" fontSize="0.8rem">
                {data?.indoorUnit1}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
        {showBrands !== false && (
          <Stack
            p={2}
            sx={{
              borderRadius: "25px",
              border: "1px solid",
              borderColor: "action.active",
            }}
            width="50%"
            flex={1.5}
            spacing={1.5}
          >
            <Stack maxWidth="50%">{brandLogo}</Stack>
            <Typography variant="body1" fontSize="0.8rem">
              {data?.equipmentBrandRecommendation}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default SystemInfo;
