import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";
import { OnboardingPayload } from "@library/domain/onboarding";

export const schema = (payload: Partial<OnboardingPayload>) => {
  return {
    type: "object",
    properties: {
      user: {
        required: ["firstName", "lastName", "email"],
        type: "object",
        properties: {
          firstName: {
            title: "First name",
            type: "string",
          },
          lastName: {
            title: "Last name",
            type: "string",
          },
          email: {
            title: "Email",
            type: "string",
            readOnly:
              payload?.user?.email && payload?.user?.supabaseId ? true : false,
          },
        },
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 1,
    maxWidth: "500px",
    user: {
      "ui:ObjectFieldTemplate": ObjectFieldTemplate,
      pt: 2,
      pb: 2,
      px: 1,
      p: 1,
      "ui:options": {
        label: false,
      },
      firstName: {
        "ui:autocomplete": "off",
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      lastName: {
        "ui:autocomplete": "off",
        xs: 12,
        sm: 6,
        md: 6,
        lg: 6,
      },
      email: {
        xs: 12,
        sm: 12,
        md: 12,
        lg: 12,
      },
    },
  };
};
