import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["heatingEquipmentType"],
    properties: {
      heatingEquipmentType: {
        ...props.heatingEquipmentType,
        title: "Heating Equipment Type",
        minItems: 1,
      },
    },
    dependencies: {
      heatingEquipmentType: {
        oneOf: [
          {
            properties: {
              heatingEquipmentType: {
                ...props.heatingEquipmentType,
                contains: { const: "none_of_these" },
              },
              heatingEquipmentTypeMoreInfo: {
                ...props.heatingEquipmentTypeMoreInfo,
              },
            },
          },
        ],
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 3,
    maxWidth: "800px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    heatingEquipmentType: {
      "ui:widget": "CheckboxImages",
      "ui:autocomplete": "off",
      xs: 6,
      sm: 3,
      md: 3,
      lg: 3,
    },
    heatingEquipmentTypeMoreInfo: {
      width: "400px",
      mt: 1,
      mb: 2,
      multiline: true,
    },
  };
};
