"use client";

import React, { createContext } from "react";
import { Button, Grid, Stack, Typography, useTheme } from "@mui/material";
import { EstimateData } from "@library/domain/estimate";
// import type { Estimate } from "@prisma/client";

import CostSummaryList from "./CostSummaryList";
import EstimateNextSteps from "./EstimateNextSteps";
import EstimateSolution from "./EstimateSolution";
import YourSystem from "./YourSystem";

export const EstimateContext = createContext<{
  estimateId: string;
  tier: string;
  estimate: Partial<EstimateData>;
}>({
  estimateId: "",
  tier: "",
  estimate: {},
});

export const EstimateDetail = ({
  estimateId,
  tier,
  estimate,
  quoteId,
  filter,
  setError,
  setWarning,
  setShowCalendly,
  draft = false,
}: {
  quoteId: string;
  tier: string;
  estimateId: string;
  estimate: Partial<EstimateData>;
  filter?: string | undefined;
  setError: (_value: string) => void;
  setWarning: (_value: string) => void;
  setShowCalendly: (_value: boolean) => void;
  draft?: boolean;
}) => {
  const theme = useTheme();
  return (
    <EstimateContext.Provider value={{ estimateId, tier, estimate }}>
      <Stack sx={{ top: "-60px", position: "relative" }}>
        <Stack height="100%" spacing={1}>
          <EstimateSolution
            estimateId={estimateId}
            quoteId={quoteId}
            filter={filter}
            setError={setError}
            setWarning={setWarning}
            setShowCalendly={setShowCalendly}
            draft={draft}
          />
          <Stack
            direction={["column", "column", "row"]}
            alignItems={["center", "center", "space-evenly"]}
            justifyItems="center"
            flex={1}
          >
            <Stack flex={1} mt={2}>
              <Typography
                flexGrow={1}
                style={{
                  fontSize: "2.5rem",
                  fontWeight: theme.typography.fontWeightBold,
                }}
              >
                System Details
              </Typography>
            </Stack>
            <Stack flex={1}>
              {/* eslint-disable-next-line no-constant-condition */}
              {false ? (
                <Stack
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                  flexGrow={1}
                  mt={[4, 4, 2]}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "0.8rem",
                      fontWeight: theme.typography.fontWeightRegular,
                    }}
                  >
                    Want to review more details?
                  </Typography>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      backgroundColor: "rgba(255,255,255,0.2)",
                      boxShadow: "none",
                      fontSize: "0.8rem",
                      minWidth: "150px",
                      borderRadius: "25px",
                      borderWidth: "1px",
                      borderColor: theme.palette.secondary.main,
                      textTransform: "none",
                      marginLeft: "1.0rem",
                      padding: "10px 20px",
                      "&:hover": {
                        borderWidth: "1px",
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.contrastText,
                      },
                    }}
                  >
                    Your System Design
                  </Button>
                </Stack>
              ) : null}
            </Stack>
          </Stack>
          <Grid container padding={0} pt={0}>
            <Grid item xs={12} sm={12} md={6} pr={[0, 0, 2]}>
              <YourSystem
                filter={filter}
                setWarning={setWarning}
                draft={draft}
              />
              <Stack
                mt={2}
                sx={{
                  borderRadius: "25px",
                  border: [null, null, "1px solid"],
                  borderColor: [null, null, "action.active"],
                }}
              >
                <EstimateNextSteps
                  setShowCalendly={setShowCalendly}
                  quoteId={quoteId}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} pl={[0, 0, 2]}>
              <CostSummaryList filter={filter} />
            </Grid>
          </Grid>
        </Stack>
        {/* {showNextSteps && <FromHere quoteId={quoteId} />} */}
      </Stack>
    </EstimateContext.Provider>
  );
};

export default EstimateDetail;
