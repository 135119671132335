import React from 'react';

import WoodPanelImage from './WoodPanel.png';

export const WoodPanel = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={WoodPanelImage} style={{ ...styles }} />
  );
};
export default WoodPanel;
