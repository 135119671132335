import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["issues"],
    properties: {
      issues: {
        ...props.issues,
      },
      issuesMoreInfo: {
        ...props.issuesMoreInfo,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    maxWidth: "700px",
    mt: 3,
    spacing: 2,
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    issues: {
      "ui:widget": "CheckboxImages",
      "ui:autocomplete": "off",
      xs: 6,
      sm: 6,
      md: 6,
      lg: 6,
    },
    issuesMoreInfo: {
      "ui:widget": "TextWidget",
      "ui:autocomplete": "off",
      multiline: true,
      margin: 0,
      paddingLeft: 1,
      paddingRight: 1,
    },
  };
};
