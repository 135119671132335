import api from "@library/api";
import { formatCurrency } from "@library/common";
import { OnboardingPayload } from "@library/domain/onboarding";
import { Theme } from "@mui/material";

interface IncomeRange {
  incomeAmount: number;
}

export const buildIncomeOptions = (data: IncomeRange[]) => {
  const incomes = data?.map((x) => x.incomeAmount) ?? [];

  if (!incomes.length) return [];

  const [lim80, lim120, lim150] = incomes;
  const incomeLabels = [
    { const: "Less than 80% AMI", title: `Less than ${formatCurrency(lim80)}` },
    {
      const: "Between 80% AMI and 120% AMI",
      title: `Between ${formatCurrency(lim80)} - ${formatCurrency(lim120)}`,
    },
    {
      const: "Between 120% AMI and 150% AMI",
      title: `Between ${formatCurrency(lim120)} - ${formatCurrency(lim150)}`,
    },
    {
      const: "Greater than 150% AMI",
      title: `More than ${formatCurrency(lim150)}`,
    },
  ];

  return incomeLabels;
};

export const fetchOnLoad = async ({
  slideState = { HouseHoldIncomeStep: {} },
  payload = {},
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  slideState: any;
  payload: Partial<OnboardingPayload>;
  theme: Theme;
}) => {
  const { data } = await api.get("onboarding/fips", {
    peopleLiveInHome: payload.peopleLiveInHome,
    fips: payload.user?.fipsCountyCode ?? "26163",
  });
  const incomeOptions = buildIncomeOptions(data);
  const HouseHoldIncomeStep = slideState.HouseHoldIncomeStep ?? {};
  return {
    ...slideState,
    HouseHoldIncomeStep: {
      ...HouseHoldIncomeStep,
      incomeOptions,
    },
  };
};
