import React from "react";

export const Kitchen = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M69.981 10.9702H30.0191C28.4614 10.9702 27.1987 12.233 27.1987 13.7906V78.3268C27.1987 79.8845 28.4614 81.1472 30.0191 81.1472H69.981C71.5386 81.1472 72.8014 79.8845 72.8014 78.3268V13.7906C72.8014 12.233 71.5386 10.9702 69.981 10.9702Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8511 30.4106V34.2802"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.8511 50.9409V54.8104"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.1927 42.522H27.6323"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.4081 81.4282V89.0296H29.5913V81.4282"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Kitchen;
