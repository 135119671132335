import React, { useContext } from "react";
import { Divider, Stack, Typography, useTheme } from "@mui/material";

import { formatCurrency } from "@library/common";
import { EstimateContext } from "./EstimateDetail";
import AccordionContainer from "./AccordionContainer";
import { CostSummaryItemAccordion } from "./CostSummaryItemAccordion";

const CostSummaryList = ({ filter }: { filter?: string | undefined }) => {
  const { estimate } = useContext(EstimateContext);
  const theme = useTheme();

  if (!estimate) return null;
  const data = estimate;

  const costSummary = [
    {
      title: "HVAC Equipment / Installation",
      items: [
        {
          description: "Outdoor Equipment",
          qty: 1,
          totalPrice: data?.outdoorEquipmentCost ?? 0,
        },
        {
          description: "Indoor Equipment",
          qty: 1,
          totalPrice: data?.indoorEquipmentCost ?? 0,
        },
        {
          description: "Balance of System",
          qty: 1,
          totalPrice: data?.balanceofSystemCost ?? 0,
        },
        {
          description: "Distributor / Installer",
          qty: 1,
          totalPrice: data?.distributorInstallerCost ?? 0,
        },
        {
          description: "Ductwork",
          qty: 1,
          totalPrice: data?.ductworkCost ?? 0,
        },
        {
          description: "Demolition / Decommissioning",
          qty: 1,
          totalPrice: data?.demoCost ?? 0,
        },
        {
          description: "Permitting",
          qty: 1,
          totalPrice: data?.permittingCost ?? 0,
        },
      ],
    },
    {
      title: "Electrical",
      items: [
        {
          description: "Service Panel Upgrade",
          qty: 1,
          totalPrice: data?.servicePanelCost ?? 0,
        },
        {
          description: "Electrical Wiring",
          qty: 1,
          totalPrice: data?.wiringCost ?? 0,
        },
        {
          description: "Electrician / Contractor",
          qty: 1,
          totalPrice: data?.electricianCost ?? 0,
        },
      ],
    },
    {
      title: "Weatherization",
      items: [
        {
          description: "Duct Sealing / Insulation",
          qty: 1,
          totalPrice: data?.ductSealingInsulationCost ?? 0,
        },
        {
          description: "Air Sealing",
          qty: 1,
          totalPrice: data?.airSealingCost ?? 0,
        },
        {
          description: "Insulation",
          qty: 1,
          totalPrice: data?.insulationCost ?? 0,
        },
        {
          description: "General Weatherization",
          qty: 1,
          totalPrice: data?.otherWeatherizationCost ?? 0,
        },
      ],
    },
    {
      title: "Other",
      items: [
        {
          description: "Remediation",
          qty: 1,
          totalPrice: data?.remediationCost ?? 0,
        },
        {
          description: "Code Compliance",
          qty: 1,
          totalPrice: data?.codeComplianceCost ?? 0,
        },
        {
          description: "Other Cost",
          qty: 1,
          totalPrice: data?.otherCost ?? 0,
        },
      ],
    },
  ];

  const rebateSummary = [
    {
      title: "Rebates, Credits, and Discounts",
      items: [
        {
          description: "Federal Rebates / Grants",
          qty: 1,
          totalPrice: data?.federalRebate ?? 0,
        },
        {
          description: data?.muniRebateName?.length
            ? data?.muniRebateName
            : "Municipal Rebate",
          qty: 1,
          totalPrice: data?.muniRebate ?? 0,
        },
        {
          description: "State Rebates / Grants",
          qty: 1,
          totalPrice: data?.stateRebate ?? 0,
        },
        {
          description: "Utility Rebate",
          qty: 1,
          totalPrice: data?.utilityRebate ?? 0,
        },
        {
          description: "Pearl Rebate",
          qty: 1,
          totalPrice: data?.pearlRebate ?? 0,
        },
      ],
    },
  ];

  return (
    <Stack
      p={0}
      pt={2}
      sx={{
        borderRadius: "25px",
        border: "1px solid",
        color: "action.active",
        marginTop: "1.0rem",
      }}
    >
      <Stack p={2} spacing={1}>
        <Stack>
          <Typography
            color="primary.main"
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            Your Estimate Breakdown
          </Typography>
          <Typography
            variant="caption"
            color="grey.500"
            fontSize={10}
          ></Typography>
        </Stack>
        <Divider sx={{ bgcolor: "action.active" }} />
        <AccordionContainer>
          {costSummary.map((e) => (
            <CostSummaryItemAccordion
              key={e.title}
              item={e}
              costItemsSx={{
                color: "initial",
                fontWeight: theme.typography.fontWeightLight,
              }}
              filter={filter}
            />
          ))}
        </AccordionContainer>
        <Divider sx={{ bgcolor: "action.active" }} />
        <Stack direction="row" spacing={2} width="100%" my={2}>
          <Typography
            textAlign="left"
            color="primary.main"
            flexGrow={1}
            sx={{ fontWeight: theme.typography.fontWeightMedium }}
          >
            Installed Cost
          </Typography>
          <Typography
            textAlign="right"
            color="primary.main"
            sx={{ fontWeight: theme.typography.fontWeightMedium, filter }}
          >
            {formatCurrency(data?.installedCostTotal)}
          </Typography>
        </Stack>
      </Stack>
      <Stack bgcolor="primary.main" px={2}>
        <AccordionContainer>
          {rebateSummary.map((e) => (
            <CostSummaryItemAccordion
              key={e.title}
              item={e}
              backgroundColor="primary.main"
              color="primary.contrastText"
              costItemsSx={{
                opacity: 0.8,
                color: theme.palette.primary.contrastText,
              }}
              filter={filter}
            />
          ))}
        </AccordionContainer>
      </Stack>
      <Stack px={2} pb={2} pt={2} spacing={1.2}>
        <Stack direction="row" spacing={2} width="100%" mt={2}>
          <Typography textAlign="left" color="primary.main" flexGrow={1}>
            Your Price
          </Typography>
          <Typography textAlign="right" color="primary.main" sx={{ filter }}>
            {formatCurrency(data?.upFrontCostTotal ?? "")}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} width="100%" mt={2}>
          <Typography textAlign="left" color="primary.main" flexGrow={1}>
            Federal Tax Credit
          </Typography>
          <Typography textAlign="right" color="primary.main" sx={{ filter }}>
            {formatCurrency(data?.incentivesTotal ?? "")}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2} width="100%" mt={2}>
          <Typography
            textAlign="left"
            color="primary.main"
            flexGrow={1}
            sx={{ fontWeight: theme.typography.fontWeightBold }}
          >
            Net Price
          </Typography>
          <Typography
            textAlign="right"
            color="primary.main"
            sx={{ fontWeight: theme.typography.fontWeightBold, filter }}
          >
            {formatCurrency(data?.netPrice ?? "")}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CostSummaryList;
