import React from 'react';

import DrywallImage from './Drywall.png';

export const Drywall = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={DrywallImage} style={{ ...styles }}/>
  );
};
export default Drywall;
