import React, { useCallback, useContext, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

import { QuoteUserInput } from "@library/domain/quote";

import api from "@library/api";
import {
  formatCurrency,
  formatNumber,
  camelCaseToHuman,
  TIERS,
} from "@library/common";
import { QuoteContext } from "./";
// import EstimateNextSteps from "./EstimateNextSteps";
import { EstimateContext } from "./EstimateDetail";
import EstimateNextSteps from "./EstimateNextSteps";
import { getLanguage } from "@library/theme/multitenancy";

const SolutionButton = ({ title = "Go" }) => {
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      color="secondary"
      sx={{
        backgroundColor: "rgba(255,255,255,0.2)",
        boxShadow: "none",
        padding: "0.6rem",
        fontSize: "0.8rem",
        minWidth: "150px",
        borderRadius: "25px",
        borderWidth: "2px",
        borderColor: theme.palette.secondary.main,
        textTransform: "none",
        "&:hover": {
          borderWidth: "2px",
          backgroundColor: theme.palette.secondary.main,
          color: theme.palette.primary.contrastText,
        },
      }}
    >
      {title}
    </Button>
  );
};

const EstimateSolution = ({
  quoteId,
  filter,
  // setError,
  setWarning,
  setShowCalendly,
  draft = false,
}: {
  quoteId: string;
  estimateId: string;
  filter?: string | undefined;
  setError: (_value: string) => void;
  setWarning: (_value: string) => void;
  setShowCalendly: (_value: boolean) => void;
  draft?: boolean;
}) => {
  const theme = useTheme();
  const [fasterInstallationState, setFasterInstallationState] = useState(false);
  const { estimate, tier } = useContext(EstimateContext);
  const { quote } = useContext(QuoteContext);
  const userInput = (quote?.userInput as QuoteUserInput) ?? {};

  const { fasterInstallation = fasterInstallationState } = userInput;

  const updateQuoteStatusHandler = useCallback(
    (key: string, value: string | boolean) => {
      if (draft) return;
      api
        .put("quote", { userInput: { [key]: value } }, { id: quoteId })
        .catch(() => {
          setWarning(
            "A backend error occured while carrying out your last request."
          );
        });
    },
    [quoteId, setWarning, draft]
  );

  if (!estimate) return null;

  const data = estimate;

  const title =
    `${theme.config.language[getLanguage(theme)].tier?.[tier] ?? TIERS[tier as keyof typeof TIERS] ?? camelCaseToHuman(tier ?? "")} Solution`.replace(
      "Solution Solution",
      "Solution"
    );

  return (
    <>
      <Stack direction="row" pt={[0, 5]} spacing={5} flexGrow={1}>
        <Typography
          ml={2}
          flexGrow={1}
          sx={{
            fontSize: ["1.8rem", "2.5rem"],
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          The {title}
        </Typography>
      </Stack>
      <Stack
        borderRadius="25px"
        sx={{
          border: [null, null, "1px solid"],
          borderColor: [null, null, "action.active"],
        }}
        py={0}
        px={0}
        spacing={0.5}
        flex={1}
        style={{
          position: "relative",
        }}
      >
        <Stack
          margin={2}
          p={0}
          direction={["column", "column", "row"]}
          justifyContent="space-between"
          alignItems="top"
          sx={{
            borderBottom: [null, null, "1px solid"],
            borderColor: [null, null, "action.active"],
            borderTopLeftRadius: "25px",
            borderTopRightRadius: "25px",
          }}
        >
          <Stack
            flex={1}
            p={[0, 0, 3]}
            spacing={1}
            sx={{
              borderRight: [null, null, "1px solid"],
              borderColor: [null, null, "action.active"],
            }}
          >
            <Typography
              color="primary.main"
              lineHeight="1"
              style={{
                fontSize: "1.6rem",
                lineHeight: "2.0rem",
                fontWeight: theme.typography.fontWeightBold,
              }}
            >
              {data?.recommendation}
            </Typography>
            <Typography
              variant="body1"
              align="left"
              sx={{
                fontSize: "1.0rem",
              }}
            >
              {data?.recommendationExplanation}
            </Typography>
          </Stack>
          <EstimateNextSteps
            setShowCalendly={setShowCalendly}
            quoteId={quoteId}
          />
        </Stack>
        <Stack
          sx={{
            borderBottomLeftRadius: "25px",
            borderBottomRightRadius: "25px",
          }}
          margin={2}
          p={0}
          px={[0, 0, 1]}
          py={2}
          pb={4}
          direction="column"
          justifyContent="space-between"
          alignItems={["flex-start", "flex-start", "center"]}
          flex={1}
          spacing={1}
        >
          {/* Start up-front cost */}
          {data?.upFrontCostTotal ? (
            <Stack
              direction={["column", "column", "row"]}
              flexGrow={1}
              pt={[2, 2, 1]}
              width="100%"
              alignItems={["left", "left", "center"]}
            >
              <Stack flexGrow={1} p={[0, 0, 2]} flex={1} spacing={1}>
                <Typography
                  color="primary.main"
                  lineHeight="1"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  Up-front Cost
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.0rem",
                  }}
                >
                  Your price, due in full after installation
                </Typography>
              </Stack>
              <Stack flexGrow={1} px={[0, 0, 1]} flex={4} mt={[2, 2, 0]}>
                <Stack
                  borderRadius="25px"
                  sx={{
                    border: "1px solid",
                    borderColor: "action.active",
                  }}
                  py={0}
                  px={0}
                  spacing={0.5}
                  alignItems="center"
                  flex={1}
                >
                  <Stack
                    margin={0}
                    p={0}
                    direction={["column", "row", "row"]}
                    justifyContent="space-between"
                    alignItems="stretch"
                    height="100%"
                    width="100%"
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      p={[2, 0, 0]}
                      sx={{
                        borderRight: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                      flex={1}
                    >
                      <Stack flexGrow={1} spacing={1} alignItems="center">
                        <Typography
                          color="primary.main"
                          textAlign="center"
                          lineHeight="1"
                          style={{
                            fontSize: "2.0rem",
                            fontWeight: theme.typography.fontWeightBold,
                            filter,
                          }}
                        >
                          <span
                            style={{
                              fontSize: "1.4rem",
                              position: "relative",
                              top: "-3px",
                            }}
                          >
                            $
                          </span>
                          {formatCurrency(data?.upFrontCostTotal ?? "", {
                            empty: true,
                            sign: false,
                          })}
                          {data.upFrontCost}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary.main"
                          align="left"
                          sx={{
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                          }}
                        >
                          Up-Front Cost
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={1} p={2} direction="row">
                      <Stack py={0} px={2} flex={1} spacing={0.8}>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="body2" align="left">
                            Cost
                          </Typography>
                          <Typography
                            variant="body2"
                            align="right"
                            sx={{ filter }}
                          >
                            {formatCurrency(data?.installedCostTotal ?? "", {
                              empty: true,
                            })}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="body2" align="left">
                            Rebates
                          </Typography>
                          <Typography
                            variant="body2"
                            align="right"
                            sx={{ filter }}
                          >
                            -
                            {formatCurrency(data?.rebatesTotal ?? "", {
                              empty: true,
                            })}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography
                            variant="body2"
                            color="primary.main"
                            align="left"
                          >
                            Up Front Cost
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary.main"
                            align="right"
                            sx={{ filter }}
                          >
                            {formatCurrency(data?.upFrontCostTotal ?? "", {
                              empty: true,
                            })}
                          </Typography>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="body2" align="left">
                            Tax Credit
                          </Typography>
                          <Typography
                            variant="body2"
                            align="right"
                            sx={{ filter }}
                          >
                            -
                            {formatCurrency(data?.incentivesTotal ?? "", {
                              empty: true,
                            })}
                          </Typography>
                        </Stack>
                        <Stack
                          sx={{
                            borderTop: "1px solid",
                            borderColor: "action.active",
                          }}
                        />
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography
                            variant="body2"
                            color="primary.main"
                            align="left"
                            sx={{
                              fontWeight: theme.typography.fontWeightMedium,
                            }}
                          >
                            Net Price
                          </Typography>
                          <Typography
                            variant="body2"
                            color="primary.main"
                            align="right"
                            sx={{
                              fontWeight: theme.typography.fontWeightMedium,
                              filter,
                            }}
                          >
                            {formatCurrency(data?.netPrice ?? "", {
                              empty: true,
                            })}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      p={[2, 0, 0]}
                      alignItems="center"
                      direction="row"
                      flex={1}
                      sx={{
                        borderLeft: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                    >
                      {/* eslint-disable-next-line no-constant-condition */}
                      {false ? (
                        <Stack
                          flexGrow={1}
                          spacing={2}
                          px={5}
                          alignItems="center"
                          textAlign="center"
                        >
                          <Typography variant="body1" fontSize="0.9rem">
                            Want financing? <br /> Review your options
                          </Typography>
                          <SolutionButton title="Go" />
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
          {/* End up-front cost */}

          {/* Start bill impact */}
          {data?.billImpactEstimate ? (
            <Stack
              direction={["column", "column", "row"]}
              flexGrow={1}
              pt={[2, 2, 1]}
              width="100%"
              alignItems={["left", "left", "center"]}
            >
              <Stack flexGrow={1} p={[0, 0, 2]} flex={1} spacing={1}>
                <Typography
                  color="primary.main"
                  lineHeight="1"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  Bill Impact
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.0rem",
                  }}
                >
                  Reduction in your annual utility bills
                </Typography>
              </Stack>
              <Stack flexGrow={1} px={[0, 0, 1]} flex={4} mt={[2, 2, 0]}>
                <Stack
                  borderRadius="25px"
                  sx={{
                    border: "1px solid",
                    borderColor: "action.active",
                  }}
                  py={0}
                  px={0}
                  spacing={0.5}
                  alignItems="center"
                  flex={1}
                >
                  <Stack
                    margin={0}
                    p={0}
                    direction={["column", "row", "row"]}
                    justifyContent="space-between"
                    alignItems="stretch"
                    height="100%"
                    width="100%"
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      p={[2, 0, 0]}
                      sx={{
                        borderRight: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                      flex={1}
                    >
                      <Stack
                        flexGrow={1}
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: "100px" }}
                      >
                        <Typography
                          color="primary.main"
                          textAlign="center"
                          lineHeight="1"
                          style={{
                            fontSize: "2.0rem",
                            fontWeight: theme.typography.fontWeightBold,
                            filter,
                          }}
                        >
                          <>
                            {data?.billImpactEstimate ? (
                              <span
                                style={{
                                  fontSize: "1.4rem",
                                  position: "relative",
                                  top: "-3px",
                                }}
                              >
                                $
                              </span>
                            ) : (
                              ""
                            )}
                            {data?.billImpactEstimate
                              ? formatCurrency(data?.billImpactEstimate ?? "", {
                                  empty: true,
                                  sign: false,
                                  increaseDecrease: false,
                                  showNegative: false,
                                })
                              : ""}
                            {data?.billImpactEstimate ? (
                              <span
                                style={{
                                  fontSize: "1.0rem",
                                  position: "relative",
                                  top: "-3px",
                                  paddingLeft: "5px",
                                }}
                              >
                                {data?.billImpactEstimate <= 0
                                  ? "decrease"
                                  : "increase"}
                              </span>
                            ) : (
                              "TBD"
                            )}
                          </>
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary.main"
                          align="left"
                          sx={{
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                          }}
                        >
                          Bill Impact
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={1} p={2} direction="row">
                      <Typography
                        variant="body1"
                        align="left"
                        fontSize="0.9rem"
                        my={2}
                        px={2}
                      >
                        {/* @TODO templatize this? */}
                        {data?.billImpactExplanation ?? ""}
                      </Typography>
                    </Stack>
                    <Stack
                      p={[2, 0, 0]}
                      alignItems="center"
                      direction="row"
                      flex={1}
                      sx={{
                        borderLeft: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                    >
                      {/* eslint-disable-next-line no-constant-condition */}
                      {false ? (
                        <Stack
                          flexGrow={1}
                          spacing={2}
                          px={5}
                          alignItems="center"
                          textAlign="center"
                        >
                          <Typography variant="body1" fontSize="0.8rem">
                            Answer a few questions to improve your estimate
                          </Typography>
                          <SolutionButton title="Go" />
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
          {/* End bill impact */}

          {/* Start climate impact */}
          {data?.climateImpactEstimate ? (
            <Stack
              direction={["column", "column", "row"]}
              flexGrow={1}
              pt={[2, 2, 1]}
              width="100%"
              alignItems={["left", "left", "center"]}
            >
              <Stack flexGrow={1} p={[0, 0, 2]} flex={1} spacing={1}>
                <Typography
                  color="primary.main"
                  lineHeight="1"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  Climate Impact
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.0rem",
                  }}
                >
                  GHG emissions avoided over 20 years
                </Typography>
              </Stack>
              <Stack flexGrow={1} px={[0, 0, 1]} flex={4} mt={[2, 2, 0]}>
                <Stack
                  borderRadius="25px"
                  sx={{
                    border: "1px solid",
                    borderColor: "action.active",
                  }}
                  py={0}
                  px={0}
                  spacing={0.5}
                  alignItems="center"
                  flex={1}
                >
                  <Stack
                    margin={0}
                    p={0}
                    direction={["column", "row", "row"]}
                    justifyContent="space-between"
                    alignItems="stretch"
                    height="100%"
                    width="100%"
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      p={[2, 0, 0]}
                      sx={{
                        borderRight: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                      flex={1}
                    >
                      <Stack
                        flexGrow={1}
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: "100px" }}
                      >
                        <Typography
                          color="primary.main"
                          textAlign="center"
                          lineHeight="1"
                          style={{
                            fontSize: "2.0rem",
                            fontWeight: theme.typography.fontWeightBold,
                            filter,
                          }}
                        >
                          <>
                            {data?.climateImpactEstimate
                              ? formatNumber(data?.climateImpactEstimate ?? 0, {
                                  showNegative: false,
                                })
                              : ""}
                            {data?.climateImpactEstimate ? (
                              <span
                                style={{
                                  fontSize: "1.4rem",
                                  position: "relative",
                                  top: "-3px",
                                  marginLeft: "4px",
                                }}
                              >
                                MT
                                <span
                                  style={{
                                    fontSize: "1.0rem",
                                    position: "relative",
                                    top: "-3px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {data?.climateImpactEstimate <= 0
                                    ? "decrease"
                                    : "increase"}
                                </span>
                              </span>
                            ) : (
                              "TBD"
                            )}
                          </>
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary.main"
                          align="left"
                          sx={{
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                          }}
                        >
                          Climate Impact
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={1} p={2} direction="row">
                      <Typography
                        variant="body1"
                        align="left"
                        fontSize="0.9rem"
                        my={2}
                        px={2}
                      >
                        {/* @TODO templatize this? */}
                        {data?.climateImpactExplanation ?? ""}
                      </Typography>
                    </Stack>
                    <Stack
                      p={[2, 0, 0]}
                      alignItems="center"
                      direction="row"
                      flex={1}
                      sx={{
                        borderLeft: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                    >
                      {/* eslint-disable-next-line no-constant-condition */}
                      {false ? (
                        <Stack
                          flexGrow={1}
                          spacing={2}
                          px={5}
                          alignItems="center"
                          textAlign="center"
                        >
                          <Typography variant="body1" fontSize="0.8rem">
                            Answer a few questions to improve your estimate
                          </Typography>
                          <SolutionButton title="Go" />
                        </Stack>
                      ) : null}
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
          {/* End climate impact */}

          {/* Start time to install impact */}
          {data?.timeToInstall ? (
            <Stack
              direction={["column", "column", "row"]}
              flexGrow={1}
              pt={[2, 2, 1]}
              width="100%"
              alignItems={["left", "left", "center"]}
            >
              <Stack flexGrow={1} p={[0, 0, 2]} flex={1} spacing={1}>
                <Typography
                  color="primary.main"
                  lineHeight="1"
                  style={{
                    fontSize: "1.2rem",
                    fontWeight: theme.typography.fontWeightBold,
                    textTransform: "uppercase",
                  }}
                >
                  Time To Install
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: "1.0rem",
                  }}
                >
                  But we can go faster if you need to...
                </Typography>
              </Stack>
              <Stack flexGrow={1} px={[0, 0, 1]} flex={4} mt={[2, 2, 0]}>
                <Stack
                  borderRadius="25px"
                  sx={{
                    border: "1px solid",
                    borderColor: "action.active",
                  }}
                  py={0}
                  px={0}
                  spacing={0.5}
                  alignItems="center"
                  flex={1}
                >
                  <Stack
                    margin={0}
                    p={0}
                    direction={["column", "row", "row"]}
                    justifyContent="space-between"
                    alignItems="stretch"
                    height="100%"
                    width="100%"
                  >
                    <Stack
                      alignItems="center"
                      direction="row"
                      p={[2, 0, 0]}
                      sx={{
                        borderRight: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                      flex={1}
                    >
                      <Stack
                        flexGrow={1}
                        spacing={1}
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: "100px" }}
                      >
                        <Typography
                          color="primary.main"
                          textAlign="center"
                          lineHeight="1"
                          style={{
                            fontSize: "2.0rem",
                            fontWeight: theme.typography.fontWeightBold,
                            filter,
                          }}
                        >
                          {data?.timeToInstall ?? ""}
                          <span
                            style={{
                              fontSize: "1.4rem",
                              position: "relative",
                              top: "-3px",
                              marginLeft: "4px",
                            }}
                          >
                            wks
                          </span>
                        </Typography>
                        <Typography
                          variant="body1"
                          color="primary.main"
                          align="left"
                          sx={{
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                          }}
                        >
                          Time To Install
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack flex={1} p={2} direction="row">
                      <Typography
                        variant="body1"
                        align="left"
                        fontSize="0.9rem"
                        my={2}
                        px={2}
                      >
                        This is our “normal” timeline, but only if you’re not in
                        a rush. Our partner contractors take emergency calls
                        seven days a week.
                      </Typography>
                    </Stack>
                    <Stack
                      p={[2, 0, 0]}
                      alignItems="center"
                      direction="row"
                      flex={1}
                      sx={{
                        borderLeft: [null, "1px solid", "1px solid"],
                        borderColor: [null, "action.active", "action.active"],
                      }}
                    >
                      <Stack
                        flexGrow={1}
                        spacing={2}
                        px={5}
                        alignItems="center"
                        textAlign="center"
                      >
                        <FormGroup
                          color="secondary"
                          sx={{ "*": { alignItems: "flex-start" } }}
                        >
                          <FormControlLabel
                            color="secondary"
                            control={
                              <Checkbox
                                onChange={(event) => {
                                  setFasterInstallationState(
                                    event.target.checked
                                  );
                                  void updateQuoteStatusHandler(
                                    "fasterInstallation",
                                    event.target.checked
                                  );
                                }}
                                defaultChecked={fasterInstallation}
                                color="secondary"
                                sx={{ "*": { alignItems: "flex-start" } }}
                              />
                            }
                            label="Request a faster installation"
                            sx={{
                              color: theme.palette.secondary.main,
                              textAlign: "left",
                              fontSize: "0.6rem !important",
                              "p, div, span": {
                                fontSize: "0.9rem !important",
                              },
                              "input[type=checkbox], svg": {
                                borderColor: theme.palette.secondary.main,
                                fill: theme.palette.secondary.main,
                                alignItems: "flex-start",
                              },
                            }}
                          />
                        </FormGroup>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          ) : null}
          {/* End climate impact */}
        </Stack>
      </Stack>
    </>
  );
};

export default EstimateSolution;
