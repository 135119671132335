import React from "react";
import pearl from "@library/theme/multitenancy/pearl/logo.png";
import amana from "@library/assets/love_amana.png";
import hisense from "@library/assets/love_hisense.png";
import mitsubishi from "@library/assets/love_mitsubishi.png";

const brands = {
  pearl,
  amana,
  hisense,
  mitsubishi
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const BrandLogos = ({ brand, styles = {}, ...props }: { brand: string, styles?: any }) => {
  return <img src={brands[brand as keyof typeof brands]} style={styles} {...props} />
};

export default BrandLogos;