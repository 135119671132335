import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { WidgetProps } from "@rjsf/utils";
import StreetView from "@library/components/StreetView";

export const StaticComponent = (props: WidgetProps) => {
  const theme = useTheme();
  const { address, lat, lng } = props.formData ?? {};
  return (
    <>
      {lat && lng && (
        <Stack justifyContent="center" alignItems="center" mt={3}>
          <Stack
            sx={{
              maxWidth: "600px",
              backgroundColor: theme.palette.primary.contrastText,
              p: 2,
              borderRadius: 2,
            }}
            alignItems="center"
            justifyContent="center"
          >
            <StreetView lat={lat} lng={lng} />
            <Typography mt={2}>{address}</Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default StaticComponent;
