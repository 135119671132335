import React from "react";

export const Office = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="64"
      height="80"
      viewBox="0 0 64 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1387 36.4004H46.8617L46.1118 14.6541C45.8608 7.04135 39.617 1 32.0002 1C24.3834 1 18.1395 7.04135 17.8885 14.6541L17.1387 36.4004Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M49.7966 43.1304H13.6531C11.8572 43.1304 10.4014 44.5862 10.4014 46.3821V48.3469C10.4014 50.1428 11.8572 51.5986 13.6531 51.5986H49.7966C51.5924 51.5986 53.0482 50.1428 53.0482 48.3469V46.3821C53.0482 44.5862 51.5924 43.1304 49.7966 43.1304Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 52.083V70.5071"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 36.75V42.9314"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.8926 74.8918C27.8926 77.1604 29.7317 78.9998 32.0004 78.9998C34.2691 78.9998 36.1084 77.1604 36.1084 74.8918C36.1084 72.6232 34.2691 70.7842 32.0004 70.7842C29.7317 70.7842 27.8926 72.6231 27.8926 74.8918Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.0004 61.6675L19.6875 68.7485"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8379 67.3737C11.8713 68.5047 11.1938 71.016 12.3249 72.9826C13.4559 74.9493 15.9673 75.6268 17.9339 74.4958C19.9006 73.3648 20.5778 70.8536 19.4467 68.8869C18.3157 66.9203 15.8045 66.2427 13.8379 67.3737Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 61.6675L44.3128 68.7485"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50.1623 67.3737C52.1288 68.5047 52.8063 71.016 51.6753 72.9826C50.5442 74.9493 48.0329 75.6268 46.0663 74.4958C44.0997 73.3648 43.4224 70.8536 44.5535 68.8869C45.6845 66.9203 48.1956 66.2427 50.1623 67.3737Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.36719 31.0181V42.5122C5.36719 45.192 7.53968 47.3645 10.2195 47.3645"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.48438 30.875H9.24896"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M58.6334 31.0181V42.5122C58.6334 45.192 56.4609 47.3645 53.7812 47.3645"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M62.5156 30.875H54.751"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Office;
