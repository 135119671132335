import { Theme } from "@mui/material";
import { AvailableTenants } from "./multitenancy";
import type { TenantName, TopLink } from "./multitenancy";

export const getTopLinks = (tenant: TenantName = "pearl") => {
  const tenantConfig =
    (AvailableTenants[tenant] as unknown as {
      theme: Theme;
      topLinks: TopLink[];
    }) ??
    (AvailableTenants.pearl as unknown as {
      theme: Theme;
      topLinks: TopLink[];
    });
  return tenantConfig.topLinks;
};
