import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;
  return {
    type: "object",
    definitions: onboardingSchema.definitions,
    required: [
      "homeType",
      "yearBuilt",
      "sqFootage",
      "aboveGroundStories",
      // "numBedrooms",
      "ceilingHeight",
      "basement",
      "attic",
      "garage",
    ],
    properties: {
      homeType: {
        type: "string",
        title: "Home Type",
        oneOf: [
          {
            const: "singleFamily",
            title: "Single Family",
          },
          {
            const: "townhouse",
            title: "Townhouse",
          },
          {
            const: "condo",
            title: "Condo",
          },
          {
            const: "duplex",
            title: "Duplex",
          },
        ],
      },
      yearBuilt: {
        ...props.yearBuilt,
      },
      sqFootage: {
        ...props.sqFootage,
      },
      aboveGroundStories: {
        ...props.aboveGroundStories,
      },
      // numBedrooms: {
      //   title: "Number of Bedrooms",
      //   type: "integer",
      //   maximum: 20,
      // },
      ceilingHeight: {
        ...props.ceilingHeight,
      },
      basement: {
        type: "string",
        oneOf: [
          {
            const: "yes",
            title: "Yes",
          },
          {
            const: "no",
            title: "No",
          },
        ],
        title: "Basement",
      },
      attic: {
        type: "string",
        oneOf: [
          {
            const: "yes",
            title: "Yes",
          },
          {
            const: "no",
            title: "No",
          },
        ],
        title: "Attic",
      },
      garage: {
        type: "string",
        oneOf: [
          {
            const: "yes",
            title: "Yes",
          },
          {
            const: "no",
            title: "No",
          },
        ],
        title: "Garage",
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    backgroundColor: "common.white",
    width: "75%",
    maxWidth: "600px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    pt: 2,
    pb: 2,
    px: 4,
    mb: 4,
    mt: 4,
    p: 0.5,
    homeType: {
      "ui:widget": "CustomSelectField",
      labelPlacement: "inline",
    },
    yearBuilt: {
      labelPlacement: "inline",
      variant: "outlined",
    },
    sqFootage: {
      labelPlacement: "inline",
      endAdornment: "sq. ft.",
      variant: "outlined",
    },
    aboveGroundStories: {
      labelPlacement: "inline",
      variant: "outlined",
    },
    ceilingHeight: {
      labelPlacement: "inline",
      endAdornment: "ft.",
      variant: "outlined",
    },
    basement: {
      "ui:widget": "CustomSelectField",
      labelPlacement: "inline",
    },
    attic: {
      "ui:widget": "CustomSelectField",
      labelPlacement: "inline",
    },
    garage: {
      "ui:widget": "CustomSelectField",
      labelPlacement: "inline",
    },
  };
};
