import React from 'react';

import StoneImage from './Stone.png';

export const Stone = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={StoneImage} style={{ ...styles }} />
  );
};
export default Stone;
