import React from 'react';

import VinylImage from './Vinyl.png';

export const Vinyl = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={VinylImage} style={{ ...styles }} />
  );
};
export default Vinyl;
