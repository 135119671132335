import React from "react";

const LivingRoom = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.3248 26.6687V8.76831C66.3248 4.47781 62.7431 1 58.3245 1H21.675C17.2566 1 13.6748 4.47781 13.6748 8.76831V26.6687"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.3246 26.6689C64.112 26.6689 61.1326 30.129 61.1326 34.2195V54.4961H18.1504V34.0752C18.1504 29.9847 14.7354 26.6689 10.5228 26.6689H8.6274C4.415 26.6689 1 29.9847 1 34.0752V69.7344H79V34.0752C79 29.9847 75.585 26.6689 71.3725 26.6689H68.3246Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5006 70.4556V79H8.17383V70.4556"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M72.3141 70.4556V79H59.9873V70.4556"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1504 40.9854H61.1326"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LivingRoom;
