import React from "react";

export const Den = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8545 27.4125L46.6116 27.6072L44.1156 6.47144H15.3505L12.8545 27.4125Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M32.6113 32.2439V27.592"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M26.8545 27.592V32.2439"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M25.3979 47.3191L23.9171 35.7853C23.7084 33.8957 25.1879 32.2439 27.0889 32.2439H32.3772C34.2783 32.2439 35.7576 33.8957 35.5489 35.7853L34.0681 47.3191"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
      <path
        d="M37.5328 53.2711H21.9326L22.6042 50.1386C22.9569 48.4939 24.4104 47.3188 26.0926 47.3188H33.3729C35.055 47.3188 36.5087 48.4939 36.8613 50.1386L37.5328 53.2711Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Den;
