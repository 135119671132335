import React from 'react';

import FiberCementImage from './FiberCement.png';

export const FiberCement = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={FiberCementImage} style={{ ...styles }} />
  );
};
export default FiberCement;
