import React, { createContext, useEffect, useState } from "react";

import { User } from "@library/domain/user";
import api from "@library/api";
import supabase from "@library/api/supabase";
import { useIntercom } from "@library/hooks/intercom";
import { isAvailableTenant, TenantName } from "@library/theme/multitenancy";
import { Channel } from "@library/domain/channel";

interface SettingsContextValue {
  error: string;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  user: User;
  setUser: (value: User) => void;
  signOut: () => void;
  channelId: string;
  channelTheme: string;
  channelData: Channel;
  setChannel: (value: string) => void;
}

export const SettingsContext = createContext<SettingsContextValue>({
  error: "",
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  isLoading: true,
  setIsLoading: () => {},
  user: {
    role: "anonymous",
  },
  setUser: () => {},
  signOut: () => {},
  channelId: "pearl",
  channelTheme: "pearl",
  channelData: {} as Channel,
  setChannel: () => {},
});

interface Props {
  children: React.ReactNode;
}

const links = [];
const formatFont = (font: string) => {
  return font.replace(/ /g, "+");
};

// Unique ID for a given channel
const DEFAULT_CHANNEL_ID = "pearl";

// Theme for a given channel (not all channels have a theme)
const DEFAULT_CHANNEL_THEME = "pearl" as TenantName;

const getChannelTheme = (channel: string | TenantName): TenantName => {
  if (isAvailableTenant(channel)) {
    return channel as TenantName;
  }
  return DEFAULT_CHANNEL_THEME;
};

const params = new URL(window?.document?.location?.toString()).searchParams;
const channel = params.get("channel");
window.localStorage.setItem(
  "channel",
  channel ?? localStorage.getItem("channel") ?? DEFAULT_CHANNEL_ID
);

if (window.location.host.includes("dte")) {
  window.localStorage.setItem("channel", "dte");
}

export const SettingsProvider: React.FC<Props> = ({ children }) => {
  const [token, setToken] = useState(
    localStorage.getItem(
      `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
    )
  );
  const [channelId, setChannelId] = useState(
    localStorage.getItem("channel") ?? DEFAULT_CHANNEL_ID
  );
  const [channelTheme, setChannelTheme] = useState<TenantName>(
    getChannelTheme(localStorage.getItem("channel") ?? DEFAULT_CHANNEL_ID)
  );
  const [channelData, setChannelData] = useState<Channel>({} as Channel);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [user, setUser] = useState<User>({
    role: "anonymous",
    firstName: "Guest",
    lastName: "User",
  });

  useEffect(() => {}, [channelId]);

  const setChannel = (channel: string | TenantName) => {
    setChannelId(channel);
    setChannelTheme(getChannelTheme(channel));
  };

  useIntercom(user, isLoading);

  useEffect(() => {
    if (links?.length) return;
    if (channelData?.config?.fonts) {
      channelData.config.fonts.forEach((font) => {
        const link = document.createElement("link");
        link.rel = "stylesheet";
        link.href = `https://fonts.googleapis.com/css2?family=${formatFont(font)}&display=swap`;
        document.getElementsByTagName("head")[0].appendChild(link);
        links.push(link);
      });
    }
  }, [channelData]);

  useEffect(() => {
    setIsLoading(true);
    api
      .get("auth/settings", { channelId })
      .then((response) => {
        const { data = {} } = response;
        const { id } = data.user ?? {};
        if (id && id.length > 1) {
          setIsAuthenticated(true);
        }
        setUser({
          ...(data.user ?? {}),
        });
        setChannelData({
          ...(data.channel ?? {}),
        });
        setIsLoading(false);
      })
      .catch(() => {
        setError("An unknown error occured, please try again.");
        setIsLoading(false);
      });
  }, [token, isAuthenticated, channelId]);

  useEffect(() => {
    supabase.auth.onAuthStateChange((event) => {
      if (window.location.href.includes("/login")) {
        if (isAuthenticated) return;
        if (event === "SIGNED_IN") {
          setIsLoading(true);
          setIsAuthenticated(true);
        }
      } else {
        if (isAuthenticated) return;
        if (event === "TOKEN_REFRESHED") {
          setIsLoading(true);
          setToken(
            localStorage.getItem(
              `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token`
            )
          );
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signOut = async () => {
    document.cookie = `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    document.cookie = `sb-${import.meta.env.VITE_SUPABASE_PROJECT_ID}-auth-token-code-verifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    const { error } = await supabase.auth.signOut();
    if (error) {
      setError(error?.message);
    } else {
      setError("");
      window.location.href = `${window.location.origin}`;
    }
  };

  return (
    <SettingsContext.Provider
      value={{
        error,
        isLoading,
        isAuthenticated,
        setIsLoading,
        setIsAuthenticated,
        user,
        setUser,
        signOut,
        channelId,
        channelTheme,
        channelData,
        setChannel,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
