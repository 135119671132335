import React, { useContext } from "react";

import { SettingsContext } from "@library/settings/provider";
import { Navigate } from "react-router-dom";

const LandingPageView = () => {
  const { isLoading, user } = useContext(SettingsContext);
  if (isLoading) return <></>;

  return (
    <>{!user.email ? <Navigate to="/onboarding" /> : <Navigate to="/concierge" />}</>
  );
};

export default LandingPageView;
