import React from "react";

interface StreetViewProps {
  apiKey?: string;
  lat: number;
  lng: number;
  width?: string;
  height?: string;
}

const StreetView: React.FC<StreetViewProps> = ({
  apiKey = import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  lat,
  lng,
  width = "400px",
  height = "250px",
}) => {
  return (
    <img
      src={`https://maps.googleapis.com/maps/api/streetview?size=400x250&location=${lat},${lng}&key=${apiKey}`}
      width={width}
      height={height}
      alt="Your house"
      title="Your house"
    />
  );
};

export default StreetView;
