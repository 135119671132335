import React from "react";
import { Divider, Stack, Typography } from "@mui/material";

import Icon1 from "@library/assets/icon1.svg";
import Icon2 from "@library/assets/icon2.svg";
import Icon3 from "@library/assets/icon3.svg";

interface StepDescriptionProps {
  title: string;
  time: string;
  description: string;
  Icon: React.ReactNode;
}

const StepDescription: React.FC<StepDescriptionProps> = ({
  title,
  time,
  description,
  Icon,
}) => (
  <Stack color="primary.contrastText" spacing={1}>
    <Stack direction="row" spacing={3} alignItems="center">
      <Stack>{Icon}</Stack>
      <Stack direction="column">
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          <Typography
            color="warning.main"
            sx={{
              fontSize: { xs: "16px", sm: "14px", md: "17px", lg: "20px" },
            }}
          >
            {title}
          </Typography>
          <Typography fontSize="12px" ml={2}>
            {time}
          </Typography>
        </Stack>
        <Typography color="primary.contrastText" fontSize="14px" mt={1}>
          {description}
        </Typography>
      </Stack>
    </Stack>
  </Stack>
);

const LineDivider = () => (
  <Divider
    sx={{ bgcolor: "grey.600", opacity: 0.5, margin: "16px 0 16px 0" }}
  />
);

const Landing: React.FC = () => {
  return (
    <Stack sx={{ maxWidth: "900px", pt: 0 }}>
      <Typography
        sx={{ fontSize: { xs: "38px", sm: "32px", md: "32px", lg: "46px" } }}
        color="primary.contrastText"
        fontWeight={"bold"}
      >
        Let&rsquo;s get started.
      </Typography>
      <Typography
        sx={{ fontSize: { xs: "16px", sm: "14px", md: "14px", lg: "20px" } }}
        color="primary.contrastText"
      >
        We&rsquo;ll help you find the right heating and air conditioning system,
        with a fair price that includes rebates and incentives, from a qualified
        contractor.
      </Typography>

      <LineDivider />

      <StepDescription
        title="Step 1: Get your instant design and estimate"
        time="~10 min"
        description="Tell us about your home and goals, then find your rebates. We&rsquo;ll design the right system for you. Get started by entering your address below to get a free quote."
        Icon={
          <Stack sx={{ width: ["50px", "75px"] }}>
            <img src={Icon1} alt="icon1" width="100%" />
          </Stack>
        }
      />
      <LineDivider />
      <StepDescription
        title="Step 2: Meet your advisor"
        time="Within 1 day"
        description="We&rsquo;ll confirm final details, answer your questions, and finalize your design."
        Icon={
          <Stack sx={{ width: ["50px", "75px"] }}>
            <img src={Icon2} alt="icon2" width="100%" />
          </Stack>
        }
      />
      <LineDivider />
      <StepDescription
        title="Step 3: Electrify your home"
        time="Within 2 weeks (expedited options available)"
        description="When you&rsquo;re ready, we&rsquo;ll schedule your installation with one of our licensed, vetted, and experienced contractor partners."
        Icon={
          <Stack sx={{ width: ["50px", "75px"] }}>
            <img src={Icon3} alt="icon3" width="100%" />
          </Stack>
        }
      />
    </Stack>
  );
};

export default Landing;
