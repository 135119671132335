import React from 'react';

import PlasterImage from './Plaster.png';

export const Plaster = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={PlasterImage} style={{ ...styles }} />
  );
};
export default Plaster;
