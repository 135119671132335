import React from 'react';
import { useTheme } from "@mui/material";

export const AttachedNoLivingSpace = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M45 60L48.4743 38.5H129.526L133 60H45Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118 116.5H108V103.013C108 100.52 110.239 98.5 113 98.5C115.761 98.5 118 100.52 118 103.013V116.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70 116.5H60V103.013C60 100.52 62.2387 98.5 65.0001 98.5C67.7615 98.5 70 100.52 70 103.013V116.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M118 83.5H108V70.013C108 67.5205 110.239 65.5 113 65.5C115.761 65.5 118 67.5205 118 70.013V83.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94 83.5H84V70.013C84 67.5205 86.2387 65.5 89.0001 65.5C91.7615 65.5 94 67.5205 94 70.013V83.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70 83.5H60V70.013C60 67.5205 62.2387 65.5 65.0001 65.5C67.7615 65.5 70 67.5205 70 70.013V83.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M172.178 91.5479L173.016 96.1423L129.212 91.0842L129.212 85.9289L172.178 91.5479Z"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M169.23 96.1714V123.171"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129 61.5V124.5H49V61.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M134.115 123.594L134.115 101.406L164.115 101.406L164.115 123.594"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.998 106.5L161.234 106.5"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.998 112.5L161.234 112.5"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M136.998 118.5L161.234 118.5"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183 124.5H35"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M82.627 122.171V105.873C82.627 102.353 85.48 99.5 88.9994 99.5C92.5188 99.5 95.3718 102.353 95.3718 105.873V122.171"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="86.0488" cy="113.5" r="1" fill="white" />
    </svg>
  );
};
export default AttachedNoLivingSpace;
