import { Address } from "@library/domain/home";

export type AddressComponentsTypes =
  | "postal_code"
  | "street_number"
  | "route"
  | "locality"
  | "administrative_area_level_1"
  | "administrative_area_level_2"
  | "administrative_area_level_3"
  | "political"
  | "country";

export interface AddressComponents {
  long_name: string;
  short_name: string;
  types: AddressComponentsTypes[];
}

export interface PlacesAddress {
  address_components: AddressComponents[];
  formatted_address: string;
  geometry: {
    location: {
      lat: number;
      lng: number;
    };
  };
}

export const getGoogleAddressPart = (
  places: PlacesAddress,
  type: AddressComponentsTypes
) => {
  const component = places?.address_components?.find((component) =>
    component.types.includes(type)
  );

  if (!component) {
    return "";
  }

  return component.short_name;
};

export const convertGoogleAddressToAddress = (
  places: PlacesAddress
): Address => {
  return {
    address1:
      getGoogleAddressPart(places, "street_number") +
      " " +
      getGoogleAddressPart(places, "route"),
    address2: "",
    city:
      getGoogleAddressPart(places, "locality") ||
      getGoogleAddressPart(places, "administrative_area_level_3"),
    state: getGoogleAddressPart(places, "administrative_area_level_1"),
    zip: getGoogleAddressPart(places, "postal_code"),
    county: getGoogleAddressPart(places, "administrative_area_level_2"),
    lat: places?.geometry?.location?.lat,
    lng: places?.geometry?.location?.lng,
  };
};

export const parseGoogleAddress = (address: string): PlacesAddress => {
  return JSON.parse(address);
};
export const formatAddress = (home: Address) => {
  return `${home.address1} ${home.address2} ${home.city}, ${home.state} ${home.zip}`;
};
