import React from 'react';

import MiniSplit1 from './MiniSplit1.png';
import MiniSplit2 from './MiniSplit2.png';
import { Stack } from '@mui/material';

export const MiniSplit = ({ styles = {} }) => {
  return (
    <Stack spacing={1}>
      <Stack>
        <img src={MiniSplit1} style={{ ...styles }} />
      </Stack>
      <Stack>
        <img src={MiniSplit2} style={{ ...styles }} />
      </Stack>
    </Stack>
  );
};
export default MiniSplit;
