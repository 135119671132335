import React from 'react';

import FurnaceWithDucts1 from './FurnaceWithDucts1.png';
import FurnaceWithDucts2 from './FurnaceWithDucts2.png';
import { Stack } from '@mui/material';

export const FurnaceWithDucts = ({ styles = {} }) => {
  return (
    <Stack spacing={1}>
      <Stack>
        <img src={FurnaceWithDucts1} style={{ ...styles }} />
      </Stack>
      <Stack>
        <img src={FurnaceWithDucts2} style={{ ...styles }} />
      </Stack>
    </Stack>
  );
};
export default FurnaceWithDucts;
