import React from "react";
import type { SxProps } from "@mui/material";
import { Stack, Typography, useTheme } from "@mui/material";

import { formatCurrency } from "@library/common";
import type { CostSummaryItem } from "./CostSummaryItemAccordion";

export const CostItems = (props: {
  items: CostSummaryItem[];
  sx?: SxProps;
  filter?: string | undefined;
}) => {
  const theme = useTheme();
  return props.items.map((item) => (
    <Stack
      direction="row"
      key={item.description}
      sx={props.sx}
      width="100%"
      spacing={2}
      justifyContent="space-evenly"
    >
      <Typography
        flex={1}
        textAlign="left"
        variant="body2"
        sx={{ fontWeight: theme.typography.fontWeightRegular, ...props.sx }}
      >
        {item.description}
      </Typography>
      <Typography
        flex={1}
        textAlign="right"
        variant="body2"
        sx={{
          fontWeight: theme.typography.fontWeightRegular,
          filter: props.filter,
          ...props.sx,
        }}
      >
        {formatCurrency(item.totalPrice, { round: true })}
      </Typography>
    </Stack>
  ));
};
