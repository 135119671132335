import React from 'react';

import SinglePaneImage from './SinglePane.png';

export const SinglePane = ({ styles = {} }: { styles: object }) => {
  return (
    <img src={SinglePaneImage} style={{ ...styles }} />
  );
};
export default SinglePane;
