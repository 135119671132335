import React, { ReactNode, useRef } from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import isFunction from "lodash/isFunction";

import { getLanguage } from "@library/theme/multitenancy";

import assets from "../assets";

const Empty = () => {
  return <></>;
};

const Component = ({
  checked,
  option,
  noImage,
  props,
  padding,
  name,
  optionValue,
  optionLabel,
  borderRadius,
  image,
  fontSize,
  placement = "side",
}: {
  checked: boolean;
  inverted: boolean;
  RadioLabel: ReactNode;
  padding: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  option: any;
  noImage: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  name: string;
  optionValue: string;
  optionLabel: string;
  image?: React.FC;
  borderRadius?: string | number;
  fontSize?: string;
  placement?: string;
}) => {
  const theme = useTheme();
  const ref = useRef<HTMLInputElement>(null);
  const Image = image as React.FC<{ styles: object }>;
  return (
    <Box
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        const _ref = ref.current;
        if (_ref?.contains(event.target as Node)) return;
        _ref?.click();
      }}
      sx={{
        cursor: "pointer",
        backgroundColor:
          option.value === props.value
            ? theme.palette.secondary.main
            : theme.palette.primary.contrastText,
        color:
          option.value === props.value
            ? theme.palette.primary.contrastText
            : "inherit",
        ":hover": {
          backgroundColor: theme.palette.secondary.dark,
          color: theme.palette.primary.contrastText,
        },
        ":hover svg.MuiSvgIcon-root": {
          fill: theme.palette.primary.contrastText,
        },
        borderRadius: 2,
        padding,
        height: "100%",
        width: "auto",
      }}
    >
      <FormControlLabel
        value={option.value}
        ref={ref}
        control={
          <Stack
            justifyContent="center"
            alignItems={placement === "bottom" ? "center" : "flex-start"}
            width="100%"
          >
            <Stack alignItems="center" sx={{ borderRadius }}>
              <Image styles={{ borderRadius, width: "100%", height: "auto" }} />
            </Stack>
            <Stack
              direction={placement === "bottom" ? "column" : "row"}
              justifyContent="flex-start"
              alignItems={placement === "bottom" ? "center" : "flex-start"}
            >
              <Stack
                sx={{ mr: placement === "bottom" ? 0 : 1 }}
                justifyContent="flex-start"
                alignItems={placement === "bottom" ? "center" : "flex-start"}
              >
                <Radio
                  value={optionValue}
                  sx={{
                    opacity: "1.0",
                    margin: "0px",
                    padding: "0px",
                    pt: 1,
                    // position: noImage ? undefined : "absolute",
                    // top: noImage ? "3px" : "15px",
                    // left: noImage ? undefined : "15px",
                    paddingRight: noImage ? "10px" : undefined,
                    color: checked
                      ? theme.palette.primary.contrastText
                      : undefined,
                    svg: {
                      fill: checked
                        ? theme.palette.primary.contrastText
                        : undefined,
                    },
                    "> span": {
                      color: checked
                        ? theme.palette.primary.contrastText
                        : undefined,
                    },
                    ":hover svg.MuiSvgIcon-root": {
                      fill: checked
                        ? theme.palette.primary.contrastText
                        : undefined,
                    },
                  }}
                />
              </Stack>
              <Stack
                alignItems={placement === "bottom" ? "center" : "flex-start"}
              >
                <Typography
                  sx={{
                    paddingTop: noImage ? "12px" : "7px",
                    width: "100%",
                    fontSize,
                    textAlign: placement === "bottom" ? "center" : undefined,
                  }}
                >
                  {theme.config.language[getLanguage(theme)]?.labels?.[
                    `${name}.enum`
                  ]?.[optionValue] ??
                    optionLabel ??
                    optionValue}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        }
        label={""}
        sx={{
          margin: "0px",
          width: "100%",
          padding: 1,
          paddingBottom: 2,
          textAlign: "left",
          span: {
            width: noImage ? "auto" : "100%",
            top: noImage ? "2px" : undefined,
          },
          ".MuiFormControlLabel-label": {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export const RadioImages = (props: WidgetProps) => {
  const theme = useTheme();
  const name = props.name as keyof typeof assets;
  const {
    title,
    spacing = 2,
    padding = 1,
    borderRadius = 10,
    backgroundColor = "initial",
    titleKey = "",
    inverted = false,
    width = ["100%"],
    xs = 6,
    sm = 4,
    md = 4,
    lg = 4,
    fontSize,
    placement,
    containerBorderRadius,
  } = props.uiSchema ?? {};
  const {
    xs: childrenXS,
    sm: childrenSM,
    md: childrenMD,
    lg: childrenLG,
    minWidth: childrenMinWidth,
  } = props.uiSchema?.children ?? {};
  return (
    <Stack
      sx={{ backgroundColor, padding: 2, borderRadius: containerBorderRadius }}
      justifyContent="center"
      alignItems="center"
    >
      {title && (
        <Typography variant="h2" mb={2} fontWeight="bold">
          {String(
            theme.config.language[getLanguage(theme)]?.labels?.[
              `${titleKey}.${name}`
            ] ?? ""
          )}
        </Typography>
      )}
      <RadioGroup
        value={props.value}
        onChange={(event) => {
          props.onChange(event.target.value);
        }}
        sx={{ width, textAlign: "center", margin: "0 auto" }}
      >
        <Grid
          container
          spacing={spacing}
          padding={padding}
          direction="row"
          alignItems="stetch"
          justifyContent="center"
        >
          {props?.options?.enumOptions?.map((option) => {
            const value = option.value as keyof (typeof assets)[typeof name];
            const label = option.label ?? value;
            const checked = value === props.value;
            let Image = assets?.[name]?.[value] as React.FC | undefined;
            let noImage = false;
            if (!isFunction(Image)) {
              Image = Empty;
              noImage = true;
            }
            const RadioLabel = <Stack sx={{ display: "none" }} />;
            return (
              <Grid
                item
                xs={childrenXS ?? xs}
                sm={childrenSM ?? sm}
                md={childrenMD ?? md}
                lg={childrenLG ?? lg}
                sx={{
                  minWidth: childrenMinWidth,
                }}
                key={value}
                alignItems="center"
                justifyContent="center"
              >
                <Component
                  padding={padding}
                  option={option}
                  checked={checked}
                  inverted={inverted}
                  RadioLabel={RadioLabel}
                  props={props}
                  value={value}
                  noImage={noImage}
                  name={name}
                  optionValue={value}
                  optionLabel={label}
                  image={Image}
                  borderRadius={borderRadius}
                  fontSize={fontSize}
                  placement={placement}
                />
              </Grid>
            );
          })}
        </Grid>
      </RadioGroup>
    </Stack>
  );
};

export default RadioImages;
