import Ajv from "ajv";

const date = new Date();
const maximumYear = date.getFullYear() + 1;

export const schemaDef = {
  definitions: {
    ATTIC: {
      enum: ["yes", "no"],
      title: "ATTIC",
      type: "string",
    },
    ATTIC_FINISH: {
      enum: ["unfinished", "partially_finished", "finished"],
      title: "ATTIC_FINISH",
      type: "string",
    },
    ATTIC_TYPE: {
      enum: ["permanent_stairs", "hatch", "no_attic"],
      title: "ATTIC_TYPE",
      type: "string",
    },
    BASEMENT: {
      enum: ["yes", "no"],
      title: "BASEMENT",
      type: "string",
    },
    BASEMENT_FINISHED: {
      enum: ["finished", "partially_finished", "unfinished"],
      title: "BASEMENT_FINISHED",
      type: "string",
    },
    BASEMENT_TYPE: {
      enum: ["below_grade", "partially_below_grade", "above_grade"],
      title: "BASEMENT_TYPE",
      type: "string",
    },
    COMMUNICATION_PREFERENCE: {
      enum: ["email", "text", "call"],
      title: "COMMUNICATION_PREFERENCE",
      type: "string",
    },
    COOLING_EQUIPMENT_TYPE: {
      enum: [
        "central_air_conditioning",
        "window_unit_air_conditioning",
        "evaporative_cooling",
        "heat_pump_mini_split",
        "something_else",
        "no_air_conditioning",
      ],
      title: "COOLING_EQUIPMENT_TYPE",
      type: "string",
    },
    DRAFTY_STUFFY: {
      enum: ["yes_drafty", "yes_stuffy", "no"],
      title: "DRAFTY_STUFFY",
      type: "string",
    },
    EQUIPMENT_TYPE: {
      enum: [
        "Heat Pump",
        "Air Conditioning Unit",
        "Boiler",
        "Furnace",
        "Air Handler Unit",
        "Heat Pump Water Heater",
      ],
      title: "EQUIPMENT_TYPE",
      type: "string",
    },
    EXTERIOR_WALLS: {
      enum: [
        "stucco",
        "vinyl",
        "fiber_cement",
        "brick",
        "wood",
        "stone",
        "metal",
      ],
      title: "EXTERIOR_WALLS",
      type: "string",
    },
    FLOORS: {
      enum: ["basement", "1", "2", "3", "attic"],
      title: "FLOORS",
      type: "string",
    },
    FLOOR_SIZE: {
      enum: [
        "second_floor_is_same_size",
        "second_floor_a_bit_smaller",
        "second_floor_a_lot_smaller",
      ],
      title: "FLOOR_SIZE",
      type: "string",
    },
    FUEL_SOURCE: {
      enum: [
        "natural_gas",
        "fuel_oil",
        "electricity",
        "something_else",
        "propane",
        "i_don_t_know",
      ],
      title: "FUEL_SOURCE",
      type: "string",
    },
    GARAGE: {
      enum: ["yes", "no"],
      title: "GARAGE",
      type: "string",
    },
    GARAGE_TYPE: {
      enum: [
        "fully_detached",
        "attached_with_no_living_space_above",
        "attached_with_living_space_above",
      ],
      title: "GARAGE_TYPE",
      type: "string",
    },
    HEATING_EQUIPMENT_TYPE: {
      enum: [
        "furnace_with_ducts",
        "boiler_with_radiators",
        "electric_resistive_with_baseboards",
        "heat_pump_mini_split",
        "none_of_these",
      ],
      title: "HEATING_EQUIPMENT_TYPE",
      type: "string",
    },
    HOME_QUALITY_TYPE: {
      enum: ["yes", "no_always_cold", "no_always_hot", "uneven"],
      title: "HOME_QUALITY_TYPE",
      type: "string",
    },
    INSTALL_TYPE: {
      enum: ["ducted", "ductless"],
      title: "INSTALL_TYPE",
      type: "string",
    },
    INTERIOR_WALLS: {
      enum: [
        "stucco",
        "drywall",
        "plaster",
        "brick",
        "wood_panel",
        "concrete",
        "ceramic_tile",
      ],
      title: "INTERIOR_WALLS",
      type: "string",
    },
    ISSUES: {
      enum: [
        "roof_damage",
        "asbestos",
        "wall_damage",
        "visible_mold",
        "missing_windows",
        "standing_water",
        "electrical_issues",
        "indoor_air_quality",
      ],
      title: "ISSUES",
      type: "string",
    },
    LoadCalculation: {
      properties: {
        ceiling: {
          title: "Ceiling",
          type: "integer",
        },
        duct_loss: {
          title: "Duct Loss",
          type: "integer",
        },
        floor: {
          title: "Floor",
          type: "integer",
        },
        infiltration: {
          title: "Infiltration",
          type: "integer",
        },
        shg: {
          title: "Shg",
          type: "integer",
        },
        wall: {
          title: "Wall",
          type: "integer",
        },
        window: {
          title: "Window",
          type: "integer",
        },
      },
      required: [
        "wall",
        "window",
        "shg",
        "ceiling",
        "floor",
        "infiltration",
        "duct_loss",
      ],
      title: "LoadCalculation",
      type: "object",
    },
    OWN_OR_RENT: {
      enum: ["rent", "own"],
      title: "OWN_OR_RENT",
      type: "string",
    },
    PEOPLE_LIVE_IN_HOME: {
      enum: [1, 2, 3, 4, 5, 6, 7, 8],
      title: "PEOPLE_LIVE_IN_HOME",
      type: "integer",
    },
    PLANS: {
      enum: ["CURRENT", "FREE", "REPLACE", "BASE", "PEARL", "EDISON"],
      title: "PLANS",
      type: "string",
    },
    ROOMS: {
      enum: [
        "KITCHEN",
        "DINING_ROOM",
        "LIVING_ROOM",
        "MASTER_BEDROOM",
        "BEDROOM",
        "MASTER_BATHROOM",
        "BATHROOM",
        "REC_ROOM",
        "MEDIA_ROOM",
        "OFFICE",
        "DEN",
        "FOYER",
        "LAUNDRY_ROOM",
      ],
      title: "ROOMS",
      type: "string",
    },
    ROOM_SIZE: {
      enum: ["SMALL", "MEDIUM", "LARGE", "XL", "XXL"],
      title: "ROOM_SIZE",
      type: "string",
    },
    Room: {
      // required: ["floor", "name", "walls", "wallsWithWindows", "size"],
      properties: {
        type: {
          $ref: "#/definitions/ROOMS",
          title: "Room type",
        },
        name: {
          type: "string",
          title: "Room name",
        },
        size: {
          $ref: "#/definitions/ROOM_SIZE",
          title: "What size?",
        },
        floor: {
          $ref: "#/definitions/FLOORS",
          title: "Which floor?",
        },
        walls: {
          title: "How many walls have windows?",
          type: "string",
        },
        wallsWithWindows: {
          title: "How many windows?",
          $ref: "#/definitions/WALLS_WITH_WINDOWS",
        },
        isCurrentlyHeated: {
          title: "Currently heating?",
          type: "boolean",
        },
        isHeatComfortIssue: {
          title: "Comfort issue?",
          type: "boolean",
        },
        heatComfortIssueInfo: {
          title: "Please explain",
          type: "string",
        },
        isCurrentlyAirConditioned: {
          title: "Currently cooling?",
          type: "boolean",
        },
        isAirConditionComfortIssue: {
          title: "Comfort issue?",
          type: "boolean",
        },
        airConditionComfortIssueInfo: {
          title: "Please explain",
          type: "string",
        },
        label: {
          title: "Label",
          type: "string",
        },
      },
      title: "Room",
      type: "object",
    },
    SupportEquipment: {
      properties: {
        category: {
          allOf: [
            {
              $ref: "#/definitions/INVOICE_SECTION",
            },
          ],
          default: "Miscellaneous",
        },
        install_price: {
          default: 0,
          title: "Install Price",
          type: "integer",
        },
        name: {
          default: "Billing Line Item",
          title: "Name",
          type: "string",
        },
        note: {
          default: "",
          title: "Note",
          type: "string",
        },
        price: {
          default: 0,
          title: "Price",
          type: "integer",
        },
        type: {
          allOf: [
            {
              $ref: "#/definitions/SUPPORT_ITEM_TYPE",
            },
          ],
          default: "Miscellaneous",
        },
      },
      title: "SupportEquipment",
      type: "object",
    },
    UPGRADES: {
      enum: [
        "added_insulation",
        "upgraded_windows",
        "air_sealing",
        "upgraded_electrical_panel",
        "duct_insulation_sealing",
        "something_else",
      ],
      title: "UPGRADES",
      type: "string",
    },
    WALLS_WITH_WINDOWS: {
      enum: ["none", "just one", "a few", "a lot", "huge ones"],
      title: "WALLS_WITH_WINDOWS",
      type: "string",
    },
    WHATS_IMPORTANT_TO_YOU: {
      enum: [
        "lowUpFrontCost",
        "lowerMonthlyBills",
        "climateImpact",
        "betterComfort",
        "somethingElse",
      ],
      title: "WHATS_IMPORTANT_TO_YOU",
      type: "string",
    },
    WHY_ARE_YOU_SHOPPING: {
      enum: [
        "equipmentBroken",
        "equipmentLastLeg",
        "moreEfficientSystem",
        "justBrowsing",
      ],
      title: "WHY_ARE_YOU_SHOPPING",
      type: "string",
    },
    WINDOW_TYPE: {
      enum: ["single", "double", "triple", "im_not_sure"],
      title: "WINDOW_TYPE",
      type: "string",
    },
    ZONE_TYPES: {
      enum: ["combine", "zone", "no_zone"],
      title: "ZONE_TYPES",
      type: "string",
    },
  },
  description: "",
  properties: {
    aboveGroundStories: {
      title: "Above-Ground Stories",
      type: "integer",
      minimum: 1,
      maximum: 3,
    },
    attic: {
      $ref: "#/definitions/ATTIC",
    },
    atticFinish: {
      $ref: "#/definitions/ATTIC_FINISH",
    },
    atticType: {
      $ref: "#/definitions/ATTIC_TYPE",
    },
    basement: {
      $ref: "#/definitions/BASEMENT",
    },
    basementFinished: {
      $ref: "#/definitions/BASEMENT_FINISHED",
    },
    basementType: {
      $ref: "#/definitions/BASEMENT_TYPE",
    },
    ceilingHeight: {
      title: "Ceiling Height",
      type: "integer",
      minimum: 6,
      maximum: 20,
    },
    communicationPreference: {
      $ref: "#/definitions/COMMUNICATION_PREFERENCE",
    },
    coolingEquipmentType: {
      items: {
        $ref: "#/definitions/COOLING_EQUIPMENT_TYPE",
      },
      title: "Cooling Equipment Type",
      type: "array",
    },
    coolingEquipmentTypeMoreInfo: {
      title: "Cooling equipment type more info",
      type: "string",
    },
    draftyStuffy: {
      $ref: "#/definitions/DRAFTY_STUFFY",
    },
    draftyStuffyMoreInfo: {
      title: "Drafty / stuffy more info",
      type: "string",
    },
    exteriorWalls: {
      $ref: "#/definitions/EXTERIOR_WALLS",
    },
    fuelSource: {
      $ref: "#/definitions/FUEL_SOURCE",
    },
    floorSize: {
      $ref: "#/definitions/FLOOR_SIZE",
    },
    garage: {
      $ref: "#/definitions/GARAGE",
    },
    garageType: {
      $ref: "#/definitions/GARAGE_TYPE",
    },
    heatingEquipmentType: {
      items: {
        $ref: "#/definitions/HEATING_EQUIPMENT_TYPE",
      },
      title: "Heating Equipment Type",
      type: "array",
    },
    heatingEquipmentTypeMoreInfo: {
      title: "Heating equipment type more info",
      type: "string",
    },
    homeQuality: {
      items: {
        $ref: "#/definitions/HOME_QUALITY_TYPE",
      },
      title: "Home Quality",
      type: "array",
    },
    homeQualityMoreInfo: {
      title: "Home quality more info",
      type: "string",
    },
    houseHoldIncome: {
      title: "House Hold Income",
      type: "string",
    },
    interiorWalls: {
      $ref: "#/definitions/INTERIOR_WALLS",
    },
    issues: {
      items: {
        $ref: "#/definitions/ISSUES",
      },
      title: "Issues",
      type: "array",
    },
    issuesMoreInfo: {
      title: "Issues more info",
      type: "string",
    },
    ownOrRent: {
      $ref: "#/definitions/OWN_OR_RENT",
    },
    peopleLiveInHome: {
      $ref: "#/definitions/PEOPLE_LIVE_IN_HOME",
    },
    phoneNumber: {
      type: "string",
      title: "Phone number",
      placeholder: "(XXX) XXX-XXXX",
    },
    rooms: {
      items: {
        $ref: "#/definitions/Room",
      },
      title: "Rooms",
      type: "array",
    },
    sqFootage: {
      title: "Sq. Footage",
      type: "integer",
      minimum: 1,
      maximum: 10000,
    },
    temperatureSummer: {
      title: "Temperature Summer",
      type: "integer",
      minimum: 40,
      maximum: 100,
    },
    temperatureWinter: {
      title: "Temperature Winter",
      type: "integer",
      minimum: 40,
      maximum: 100,
    },
    upgrades: {
      items: {
        $ref: "#/definitions/UPGRADES",
      },
      title: "Upgrades",
      type: "array",
    },
    upgradesMoreInfo: {
      title: "Upgrades more info",
      type: "string",
    },
    whatsImportantToYou: {
      items: {
        $ref: "#/definitions/WHATS_IMPORTANT_TO_YOU",
      },
      title: "Whats important to you",
      type: "array",
    },
    whatsImportantToYouInfo: {
      title: "Whats important to you info",
      type: "string",
    },
    whyAreYouShoppingToday: {
      $ref: "#/definitions/WHY_ARE_YOU_SHOPPING",
    },
    windowType: {
      $ref: "#/definitions/WINDOW_TYPE",
    },
    yearBuilt: {
      title: "Year Built",
      type: "integer",
      minimum: 1776,
      maximum: maximumYear,
    },
  },
  required: [
    // "houseHoldIncome",
    "sqFootage",
    "aboveGroundStories",
    "rooms",
    "ceilingHeight",
    "floorSize",
    "windowType",
    "yearBuilt",
    "draftyStuffy",
    "heatingEquipmentType",
    "coolingEquipmentType",
    "temperatureSummer",
    "temperatureWinter",
    "garage",
    // "garageType",
    "exteriorWalls",
    "interiorWalls",
    "attic",
    // "atticType",
    // "atticFinish",
    "basement",
    // "basementType",
    // "basementFinished",
    "upgrades",
  ],
  title: "",
  type: "object",
};

const ajv = new Ajv({ allErrors: true });
export const validate = ajv.compile(schemaDef);

export default schemaDef;
