import React from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  TextField,
  useTheme,
  InputAdornment,
  Typography,
  Grid,
  Stack,
  useMediaQuery,
} from "@mui/material";
import { getLanguage } from "@library/theme/multitenancy";

export const CustomTextField = (props: WidgetProps) => {
  const theme = useTheme();
  const small = useMediaQuery(theme.breakpoints.down("md"));
  const name = props.name;
  const {
    margin,
    width = "100%",
    padding = 0,
    paddingLeft = 0,
    paddingRight = 0,
    left = 0,
    endAdornment = "",
    variant = "filled",
    labelPlacement = "inset",
    pt,
    mt,
    mb,
    fontSize = "1.0rem",
    labelFontSize,
    multiline = false,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    format = (value: string, _previousValue: string) => value,
  } = props.uiSchema ?? {};
  const type = props.schema?.type ?? "string";
  const inputType = type === "string" ? "text" : "number";
  let Adornment = undefined;
  if (endAdornment) {
    Adornment = (
      <InputAdornment
        position="end"
        sx={{
          ".MuiInputAdornment-root": {
            backgrondColor: "transparent !important",
          },
        }}
      >
        {endAdornment}
      </InputAdornment>
    );
  }
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{
        width: "100%",
        p: 1,
        backgroundColor: props.rawErrors?.length
          ? "rgba(255,0,0,0.1)"
          : undefined,
      }}
    >
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        direction="row"
        sx={{
          padding,
          paddingLeft,
          paddingRight,
          left,
          position: "relative",
          width,
          pt,
          mt,
          mb,
        }}
      >
        <Grid
          item
          xs={labelPlacement === "inline" ? 12 : 0}
          sm={labelPlacement === "inline" ? 12 : 0}
          md={labelPlacement === "inline" ? 8 : 0}
          sx={{
            marginBottom: small ? 1 : 0,
            marginTop: small ? 1 : 0,
          }}
        >
          {labelPlacement === "inline" ? (
            <Typography>{props.label}</Typography>
          ) : null}
        </Grid>
        <Grid
          item
          xs={labelPlacement === "inline" ? 12 : 12}
          sm={labelPlacement === "inline" ? 12 : 12}
          md={labelPlacement === "inline" ? 4 : 12}
        >
          <TextField
            id={props.id}
            name={props.name}
            variant={variant}
            type={inputType}
            className="custom"
            placeholder={props.placeholder}
            value={props.value}
            disabled={props.disabled || props.readonly}
            required={props.required}
            multiline={multiline}
            onChange={(event) => {
              let value = event.target.value as string | number;
              if (type === "integer") {
                value = parseInt(String(value), 10);
              }
              if (type === "number") {
                value = parseFloat(String(value));
              }
              props.onChange(format(value, props.value));
            }}
            InputProps={{
              disableUnderline: true,
              type: inputType,
              endAdornment: Adornment,
              style: {
                textAlign: "right",
                fontSize,
              },
            }}
            InputLabelProps={{ style: { fontSize: labelFontSize ?? fontSize } }}
            sx={{
              backgroundColor: "none",
              div: {
                borderRadius: 1,
              },
              "label.MuiInputLabel-root": {
                top: multiline ? "0px" : undefined,
                left: multiline ? "0px" : undefined,
              },
              input: {
                padding: `${
                  labelPlacement === "inset" ? "25px 10px 10px" : "15px 20px"
                }`,
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: 1,
              },
              ".MuiInputBase-multiline": {
                background: "transparent",
                padding: 0,
                margin: 0,
              },
              textarea: {
                padding: `${
                  labelPlacement === "inset" ? "24px 10px 10px" : "20px 20px"
                }`,
                backgroundColor: theme.palette.primary.contrastText,
                borderRadius: 1,
              },
              margin,
              width: "100%",
              ".MuiFormLabel-asterisk": {
                display: "none",
              },
            }}
            label={
              labelPlacement === "inset"
                ? (theme.config.language[getLanguage(theme)]?.labels?.[
                    `${name}`
                  ] ?? props.label)
                : ""
            }
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default CustomTextField;
