import * as React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { SettingsProvider } from "@library/settings/provider";
import INTERCOM from "@library/scripts/intercom";
import ANALYTICS from "@library/scripts/analytics";
import { init } from "@library/scripts/sentry";
import Theme from "./Theme";

import "./App.css";

const helmetContext = {};

init();

function App() {
  return (
    <React.StrictMode>
      <HelmetProvider context={helmetContext}>
        <Helmet>
          {import.meta.env.VITE_ENV === "prod" ||
          import.meta.env.VITE_INTERCOM === "true" ? (
            <script id="intercom-load">{INTERCOM}</script>
          ) : null}
          {import.meta.env.VITE_ENV === "prod" ? (
            <script id="analyics-load">{ANALYTICS}</script>
          ) : null}
        </Helmet>
        <SettingsProvider>
          <Theme />
        </SettingsProvider>
      </HelmetProvider>
    </React.StrictMode>
  );
}

export default App;
