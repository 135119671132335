import React from "react";
import { WidgetProps } from "@rjsf/utils";

import { Box, Grid, Stack } from "@mui/material";

export const ObjectFieldTemplate = (props: WidgetProps) => {
  const { properties, uiSchema } = props;
  const {
    width = "100%",
    maxWidth = "600px",
    xs,
    sm,
    md,
    lg,
    mt = 0,
    mb = 0,
    spacing = 0,
    p = 0,
    px = 0,
    pt = 0,
    pb = 0,
    alignItems = "center",
    justifyContent = "center",
    backgroundColor = "transparent",
  } = props.uiSchema ?? {};
  return (
    <Stack justifyContent="center" alignItems="center">
      <Grid
        container
        spacing={spacing}
        mt={mt}
        mb={mb}
        pt={pt}
        pb={pb}
        alignItems={alignItems}
        justifyContent={justifyContent}
        sx={{ textAlign: "left", width, backgroundColor, maxWidth }}
      >
        {properties.map((element: WidgetProps, index: number | string) => {
          const elemUiSchema = uiSchema?.[element.name] ?? {};
          return (
            <Grid
              item
              xs={xs ?? elemUiSchema?.xs ?? undefined}
              sm={sm ?? elemUiSchema?.sm ?? undefined}
              md={md ?? elemUiSchema?.md ?? undefined}
              lg={lg ?? elemUiSchema?.lg ?? undefined}
              key={index}
            >
              <Box p={p} px={px}>{element.content}</Box>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default ObjectFieldTemplate;
