import { ReactElement } from "react";
import { Theme } from "@mui/material";
import { RJSFSchema, UiSchema } from "@rjsf/utils";
import { User } from "@supabase/supabase-js";

import { OnboardingPayload } from "@library/domain/onboarding";

import SlideDefs from "./slides/index";
import { SlideComponent } from ".";

export interface OnNextReturn {
  error?: string | ReactElement;
}

export interface SlideSchema {
  key: string;
  sectionLabel?: string;
  stepLabel?: string;
  progress?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  schema: (payload: Partial<OnboardingPayload>, slideState?: any) => RJSFSchema;
  uiSchema: (
    payload: Partial<OnboardingPayload>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slideState?: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    formRef?: any
  ) => UiSchema;
  visible?: ({
    payload,
    theme,
  }: {
    payload: Partial<OnboardingPayload>;
    theme: Theme;
  }) => boolean;
  onNext?: ({
    index,
    payload,
    setPayload,
    user,
    setUser,
    setIsAuthenticated,
    draft,
  }: {
    index: number;
    payload: Partial<OnboardingPayload>;
    setPayload: (value: Partial<OnboardingPayload>) => void;
    user: Partial<User>;
    setUser: (data: User) => void;
    setIsAuthenticated: (value: boolean) => void;
    draft?: boolean;
  }) => Promise<OnNextReturn>;
  fetchOnLoad?: ({
    payload,
    slideState,
    theme,
  }: {
    payload: Partial<OnboardingPayload>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    slideState: any;
    theme: Theme;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<any>;
  Header?: React.FC<SlideComponent>;
  Footer?: React.FC<SlideComponent>;
  Title?: React.FC<SlideComponent>;
  Description?: React.FC<SlideComponent>;
  Callout?: React.FC<SlideComponent>;
  nextTitle?: string;
  backTitle?: string;
  background?: (theme: Theme) => string;
  canSkip?: boolean;
  customSubmit?: boolean;
  customNavigation?: boolean;
  dark?: boolean;
  animation?: boolean;
}

export const slides = Object.keys(SlideDefs).map(
  (key: string) => SlideDefs[key as keyof typeof SlideDefs]
) as SlideSchema[];

export const getSlide = async (index: number) => {
  return Promise.resolve(slides[index]);
};

export const getNextSlide = async ({
  index,
  payload,
  setPayload,
  setError,
  user,
  setUser,
  setIsAuthenticated,
  executeOnNext,
  setLoading,
  theme,
  draft = false,
}: {
  index: number;
  payload: Partial<OnboardingPayload>;
  setPayload: (payload: Partial<OnboardingPayload>) => void;
  setError: (error: string | ReactElement) => void;
  user: Partial<User>;
  setUser: (user: User) => void;
  setIsAuthenticated: (value: boolean) => void;
  executeOnNext: boolean;
  setLoading: (value: boolean) => void;
  theme: Theme;
  draft?: boolean;
}): Promise<number> => {
  const slide = slides[index];
  if (slide.onNext && executeOnNext) {
    setLoading(true);
    const { error } = await slide.onNext({
      index,
      payload,
      setPayload,
      user,
      setUser,
      setIsAuthenticated,
      draft,
    });
    setLoading(false);
    if (error) {
      setError(error);
      return index;
    }
  }

  // @TODO we can have this reach out to the backend to get us the
  // "next slide" that we need, for now we are just adding one
  await Promise.resolve();

  let nextIndex = index + 1;
  let match = false;
  for (let i = nextIndex; i < slides.length; i++) {
    const nextSlide = slides[i];
    if (!nextSlide.visible) {
      match = true;
      nextIndex = i;
      break;
    } else if (nextSlide.visible({ payload, theme })) {
      match = true;
      nextIndex = i;
      break;
    }
  }
  if (!match || nextIndex >= slides.length) return -1; // We are finished!
  return nextIndex; // Next slide
};

export const getPreviousSlide = async ({
  index,
  payload,
  theme,
}: {
  index: number;
  payload: Partial<OnboardingPayload>;
  theme: Theme;
}) => {
  if (index === 0) return 0;

  // @TODO we can have this reach out to the backend to get us the
  // "next slide" that we need, for now we are just adding one
  await Promise.resolve();

  let nextIndex = index - 1;
  for (let i = nextIndex; i >= 0; i--) {
    const nextSlide = slides[i];
    if (!nextSlide.visible) {
      nextIndex = i;
      break;
    } else if (nextSlide.visible({ payload, theme })) {
      nextIndex = i;
      break;
    }
  }

  return nextIndex;
};

export const getSpecificSlide = async ({
  index,
  keyIndex,
  numIndex,
}: {
  index: number;
  keyIndex?: string;
  numIndex?: number;
}) => {
  // @TODO in the future we may ask the backend for a slide
  // by a given id
  await Promise.resolve();

  let nextIndex = index;

  // Go to a specific index
  if (numIndex) {
    if (slides[numIndex]) {
      nextIndex = numIndex;
    } else if (slides[index + 1]) {
      nextIndex = index + 1;
    } else {
      nextIndex = index;
    }
  }

  // Go to a slide by slide key
  if (keyIndex) {
    let match = false;
    for (let i = 0; i < slides.length; i++) {
      if (slides[i].key === keyIndex) {
        nextIndex = i;
        match = true;
        break;
      }
    }
    if (!match) {
      if (slides[index + 1]) {
        nextIndex = index + 1;
      } else {
        nextIndex = index;
      }
    }
  }

  return nextIndex;
};

export default slides;
