import StaticComponent from "./StaticComponent";

export const schema = () => {
  return {
    type: "object",
    properties: {
      user: {
        required: ["address"],
        type: "object",
        properties: {
          address: {
            title: "Address",
            type: "string",
          },
        },
      },
    },
  };
};

export const uiSchema = () => {
  return {
    user: {
      "ui:options": {
        label: false,
      },
      address: {
        "ui:widget": StaticComponent,
        "ui:autocomplete": "off",
      },
    },
  };
};
