import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["atticFinish"],
    properties: {
      atticFinish: {
        title: "Attic Finish",
        ...props.atticFinish,
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 1,
    maxWidth: "800px",
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    atticFinish: {
      borderRadius: 10,
      "ui:widget": "RadioImages",
      "ui:autocomplete": "off",
    },
  };
};
