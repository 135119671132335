import ObjectFieldTemplate from "@library/components/form/widgets/ObjectFieldTemplate";

import onboardingSchema from "@library/common/schema";

export const schema = () => {
  const props = onboardingSchema.properties;

  return {
    definitions: onboardingSchema.definitions,
    type: "object",
    required: ["peopleLiveInHome"],
    properties: {
      peopleLiveInHome: {
        ...props.peopleLiveInHome,
        title: "",
      },
    },
  };
};

export const uiSchema = () => {
  return {
    "ui:ObjectFieldTemplate": ObjectFieldTemplate,
    mt: 4,
    mb: 4,
    p: 2,
    peopleLiveInHome: {
      "ui:widget": "CustomSelectField",
      "ui:autocomplete": "off",
      width: "300px",
    },
  };
};
