import React from 'react';

export const Bedroom = ({ stroke = "#222222" }) => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M78.9377 78.785V22.8898C78.9377 22.8898 80.5026 0.765137 39.7974 0.765137H40.115C-0.590263 0.765137 0.974777 22.8898 0.974777 22.8898V78.785"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.42383 65.5845H78.4873"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.42383 54.4019H78.4873"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.42383 41.5037C1.42383 41.5037 33.8315 29.3895 78.4873 41.5037"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.02734 39.3535V28.0464C9.02734 26.1041 10.6017 24.5298 12.5439 24.5298H32.6794C34.6216 24.5298 36.1962 26.1041 36.1962 28.0464V35.6196"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M43.7168 36.1195V28.0464C43.7168 26.1041 45.2913 24.5298 47.2335 24.5298H67.369C69.3112 24.5298 70.8857 26.1041 70.8857 28.0464V39.6423"
      stroke={stroke}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Bedroom;
