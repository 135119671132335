import React from 'react';

import PartiallyFinishedAtticImage from './PartiallyFinishedAttic.png';

export const PartiallyFinishedAttic = ({ styles = {} }) => {
  return (
    <img src={PartiallyFinishedAtticImage} style={{ ...styles }} />
  );
};
export default PartiallyFinishedAttic;
