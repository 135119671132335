import React from 'react';
import { useTheme } from "@mui/material";

export const Detached = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M42 59.9998L45.4743 38.4998H126.526L130 59.9998H42Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115 116.5H105V103.013C105 100.521 107.239 98.5002 110 98.5002C112.761 98.5002 115 100.521 115 103.013V116.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67 116.5H57V103.013C57 100.521 59.2387 98.5002 62.0001 98.5002C64.7615 98.5002 67 100.521 67 103.013V116.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M115 83.4998H105V70.0128C105 67.5202 107.239 65.4998 110 65.4998C112.761 65.4998 115 67.5202 115 70.0128V83.4998Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M91 83.4998H81V70.0128C81 67.5202 83.2387 65.4998 86.0001 65.4998C88.7615 65.4998 91 67.5202 91 70.0128V83.4998Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67 83.4998H57V70.0128C57 67.5202 59.2387 65.4998 62.0001 65.4998C64.7615 65.4998 67 67.5202 67 70.0128V83.4998Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M178.398 93.1711V123.171"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M138.398 93.1711V123.171"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126 61.4998V124.5H46V61.4998"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.398 123.594L143.398 98.4065L173.398 98.4065L173.398 123.594"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.28 104.5L170.517 104.5"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.28 111.5L170.517 111.5"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M146.28 118.5L170.517 118.5"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M183 124.5H35"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M79.627 122.171V105.873C79.627 102.353 82.48 99.5002 85.9994 99.5002C89.5188 99.5002 92.3718 102.353 92.3718 105.873V122.171"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="83.0488" cy="113.5" r="1" fill="white" />
      <path
        d="M135.23 92.2651L137.06 85.6184H179.737L181.566 92.2651H135.23Z"
        style={{ stroke: theme.palette.secondary.light }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Detached;
