import React from 'react';
import { useTheme } from "@mui/material";

export const Icon = () => {
  const theme = useTheme();
  return (
    <svg
      width="44"
      height="45"
      viewBox="0 0 44 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 22.5C0.75 10.7639 10.2639 1.25 22 1.25C33.736 1.25 43.25 10.7639 43.25 22.5C43.25 34.236 33.736 43.75 22 43.75C10.2639 43.75 0.75 34.236 0.75 22.5Z"
        style={{ stroke: theme.palette.common.white }}
        strokeWidth="1.5"
      />
      <path
        d="M25.5234 11.5508C25.7383 11.3789 25.9961 11.25 26.2539 11.25C26.9844 11.25 27.5 12.0234 27.2422 12.7109L24.3203 20.875H29.0469C29.6914 20.875 30.25 21.4336 30.25 22.0352C30.25 22.3789 30.0781 22.7227 29.8203 22.9375L18.4336 32.9922C18.2188 33.1641 17.9609 33.25 17.7031 33.25C16.9727 33.25 16.457 32.5195 16.7148 31.832L19.6367 23.625H14.8242C14.2227 23.625 13.75 23.1523 13.75 22.5508C13.75 22.207 13.8789 21.9062 14.0938 21.6914L25.5234 11.5508ZM25.5664 13.3555L15.5547 22.25H20.625C20.8398 22.25 21.0547 22.3789 21.1836 22.5508C21.3125 22.7227 21.3125 22.9805 21.2695 23.1953L18.3906 31.1875L28.4883 22.25H23.375C23.1172 22.25 22.9023 22.1641 22.7734 21.9922C22.6445 21.7773 22.6445 21.5625 22.6875 21.3477L25.5664 13.3555Z"
        style={{ stroke: theme.palette.common.white }}
      />
    </svg>
  );
};

export default Icon;
