import React from 'react';
import { useTheme } from "@mui/material";

export const None = () => {
  const theme = useTheme();

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 218 161"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 8.5C0 4.08172 3.58172 0.5 8 0.5H210C214.418 0.5 218 4.08172 218 8.5V152.5C218 156.918 214.418 160.5 210 160.5H8C3.58173 160.5 0 156.918 0 152.5V8.5Z"
        style={{ fill: theme.palette.grey["900"] }}
      />
      <path
        d="M138.271 110.5H128.271V97.013C128.271 94.5205 130.51 92.5 133.272 92.5C136.033 92.5 138.271 94.5205 138.271 97.013V110.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M90.2715 110.5H80.2715V97.013C80.2715 94.5205 82.5102 92.5 85.2716 92.5C88.033 92.5 90.2715 94.5205 90.2715 97.013V110.5Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M149.271 86.5V118.5H69.2715V86.5"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M163.271 118.5H55.2715"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M103.271 116.171V99.8725C103.271 96.3531 106.125 93.5 109.644 93.5C113.163 93.5 116.016 96.3531 116.016 99.8725V116.171"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="106.693" cy="107.5" r="1" fill="white" />
      <path
        d="M65.2715 86L68.7458 72.5H149.797L153.271 86H65.2715Z"
        style={{ stroke: theme.palette.secondary.contrastText }}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default None;
