import React from "react";

const Bathroom = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84.6339 61.1182L80.7275 75.8C80.0947 79.6366 76.7755 82.4514 72.8844 82.4514H26.3345C22.4433 82.4514 19.1241 79.6366 18.4912 75.8L14.585 61.1182"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M86.1058 50.2744H13.3101C11.873 50.2744 10.708 51.4395 10.708 52.8766V57.8494C10.708 59.2865 11.873 60.4514 13.3101 60.4514H86.1058C87.5429 60.4514 88.708 59.2865 88.708 57.8494V52.8766C88.708 51.4395 87.5429 50.2744 86.1058 50.2744Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4287 89.1196L26.0972 82.4521"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M76.9565 89.1196L73.2881 82.4521"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.2451 19.9128C64.2451 15.0562 68.1849 11.1191 73.045 11.1191C77.905 11.1191 81.8448 15.0562 81.8448 19.9128V50.2747"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.7471 35.2832V43.6033"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M64.2451 35.2832V43.6033"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.7432 35.2832V43.6033"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M55.1826 29.1973C55.1826 24.1922 59.24 20.1348 64.2451 20.1348C69.2502 20.1348 73.3076 24.1922 73.3076 29.1973H55.1826Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Bathroom;
