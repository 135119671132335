import { RegistryWidgetsType } from "@rjsf/utils";

import AutocompleteAddress from "./AutocompleteAddress";
import RadioImages from "./RadioImages";
import CheckboxImages from "./CheckboxImages";
import TextWidget from "./CustomTextField";
import CustomSelectField from "./CustomSelectField";
import FloorPlan from "./FloorPlan";
import RoomsConfiguration from "./RoomsConfiguration";
import ComfortGrid from "./ComfortGrid";

export const widgets: RegistryWidgetsType = {
  TextWidget,
  CustomTextField: TextWidget,
  AutocompleteAddress,
  RadioImages,
  CheckboxImages,
  FloorPlan,
  RoomsConfiguration,
  ComfortGrid,
  CustomSelectField,
};

export default widgets;
