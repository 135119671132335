import { getLanguage } from "@library/theme/multitenancy";
import { Theme } from "@mui/material";

export const background = (theme: Theme) => {
  return theme.palette.primary.main;
};

export const visible = ({ theme }: { theme: Theme }) => {
  if (
    !theme.config?.language?.[getLanguage(theme)]?.step?.["GettingStartedStep"]
      ?.title
  ) {
    return false;
  }
  return true;
};
