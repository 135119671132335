import React from 'react';

import MiniSplitAirConditioningImage from './MiniSplitAirConditioning.png';

export const MiniSplitAirConditioning = ({ styles = {} }) => {
  return (
    <img src={MiniSplitAirConditioningImage} style={{ ...styles }} />
  );
};
export default MiniSplitAirConditioning;
