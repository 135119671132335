import React, { useState, CSSProperties } from "react";
import { WidgetProps } from "@rjsf/utils";
import {
  Box,
  // Button,
  // Grid,
  Stack,
  IconButton,
  useMediaQuery,
  Typography,
  useTheme,
  TextField,
  Grid,
  ButtonGroup,
  Button,
  Alert,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";

import type { StackProps, Theme } from "@mui/material";

import { getLanguage } from "@library/theme/multitenancy";

import assets from "../assets";
import cloneDeep from "lodash/cloneDeep";
import api from "@library/api";

const {
  LIVING_ROOM,
  KITCHEN,
  DINING_ROOM,
  BATHROOM,
  OFFICE,
  BEDROOM,
  DEN,
  MEDIA_ROOM,
  FOYER,
  LAUNDRY_ROOM,
} = assets.roomType;

const ROOM_TYPE_ICONS: Record<string, React.FC> = {
  LIVING_ROOM,
  KITCHEN,
  DINING_ROOM,
  BATHROOM,
  OFFICE,
  BEDROOM,
  DEN,
  MEDIA_ROOM,
  FOYER,
  LAUNDRY_ROOM,
};

type SelectButtonVariants = "selected" | "checked" | "default";

const PALLET = {
  blue: "##7CB518",
  lightGray: "rgba(34,34,34, 0.35)",
  black: "#222",
};

const CheckBadge = ({ bgcolor, color }: { bgcolor: string; color: string }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: -10,
        bgcolor: bgcolor,
        height: "24px",
        width: "24px",
        borderRadius: "15px",
        "svg path": {
          stroke: color,
          color: color,
        },
      }}
    >
      <CheckIcon
        htmlColor={color}
        sx={{
          width: "15px",
          color,
        }}
      />
    </Box>
  );
};

export interface SelectButtonProps {
  label: string;
  variant: SelectButtonVariants;
  selected?: boolean;
  SvgIcon: React.FC<{ stroke: string }>;
  onClick: () => void;
  buttonCount: number;
  error: string;
}

export const SelectButton: React.FC<SelectButtonProps> = ({
  label,
  variant,
  selected,
  SvgIcon,
  onClick,
  buttonCount,
  error,
}) => {
  const theme = useTheme();
  const VARIANTS = {
    selected: {
      border: `1px solid ${PALLET.blue}`,
      bgcolor: theme.palette.secondary.main,
      color: theme.palette.primary.contrastText,
    },
    checked: {
      bgcolor: "white",
      color: "black",
    },
    default: {
      border: `1px dashed black`,
      color: theme.palette.grey[900],
      bgcolor: "transparent",
    },
  } as Record<string, CSSProperties>;
  const { color, ...sx } = VARIANTS[selected ? "selected" : variant];
  // scale the button size based on the number of rooms
  const lessThanSmall = useMediaQuery(theme.breakpoints.down("md"));
  let baseSize = 100;
  if (lessThanSmall) {
    baseSize = 80;
  }
  let buttonSize = baseSize / (buttonCount * 0.9);
  if (buttonSize < 20) buttonSize = 20;

  const _sx = {
    position: "relative",
    p: 3,
    ...sx,
    "&:hover": {
      background: theme.palette.secondary.main,
      opacity: "0.7",
    },
    "&:hover svg path": {
      color: "white",
      stroke: "white",
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as CSSProperties & any;

  if (variant !== "checked" && error) {
    _sx.border = `2px dashed ${theme.palette.error.main}`;
    if (selected) {
      _sx.backgroundColor = theme.palette.error.main;
      _sx["&:hover"] = {
        background: theme.palette.error.main,
        opacity: "0.7",
      };
    } else {
      _sx.backgroundColor = theme.palette.error.light;
      _sx["&:hover"] = {
        background: theme.palette.error.main,
        opacity: "0.7",
      };
    }
  }

  return (
    <Stack justifyContent="center" alignItems="center" spacing={1}>
      <IconButton color="secondary" onClick={onClick} sx={_sx}>
        {variant === "checked" && (
          <CheckBadge
            bgcolor={theme.palette.success.light}
            color={theme.palette.primary.contrastText}
          />
        )}
        <Box
          sx={{
            display: "flex",
            width: `${buttonSize}px`,
            height: `${buttonSize}px`,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SvgIcon stroke={color as string} />
        </Box>
      </IconButton>
      <Typography
        variant="body2"
        align="center"
        sx={{ fontSize: ["8px", "10px", "14px"] }}
      >
        {label}
      </Typography>
    </Stack>
  );
};

interface RoundSvgButtonGroupOption {
  label: string;
  SvgIcon: React.FC;
  variant: SelectButtonVariants;
}

interface RoundSvgButtonGroupProps extends StackProps {
  values: RoundSvgButtonGroupOption[];
  error: string;
  onButtonClick?: (option: RoundSvgButtonGroupOption, index: number) => void;
}

const RoundSvgButtonGroup = ({
  values,
  onButtonClick,
  error,
  ...rest
}: RoundSvgButtonGroupProps) => {
  const handleButtonClick =
    (option: RoundSvgButtonGroupOption, index: number) => () => {
      onButtonClick && onButtonClick(option, index);
    };
  return (
    <Stack
      direction="row"
      spacing={2}
      width="100%"
      justifyContent="center"
      alignItems="flex-start"
      flexWrap="wrap"
      flex="auto"
      rowGap="20px"
      {...rest}
      sx={{
        "> div": { margin: "0px !important", marginRight: "16px !important" },
      }}
    >
      {values.map((option, index) => (
        <SelectButton
          key={index}
          onClick={handleButtonClick(option, index)}
          buttonCount={values.length}
          error={error}
          {...option}
        />
      ))}
    </Stack>
  );
};

const StyledButton = ({
  room,
  setRoom,
  field,
  value,
  theme,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  room: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setRoom: (key: string, value: any) => void;
  field: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  theme: Theme;
}) => {
  const label =
    theme.config?.language?.[getLanguage(theme)]?.labels?.[
      `rooms.${field}.enum`
    ]?.[value] ?? value;
  return (
    <Button
      variant={room[field] === value ? "contained" : "outlined"}
      onClick={() => {
        setRoom(field, value);
      }}
      data-cy="option"
      data-room={room.name}
      data-key={field}
      data-value={value}
      sx={{ fontSize: ["0.6rem", "0.8rem", "0.9rem", "1.0rem"] }}
    >
      {label}
    </Button>
  );
};

export const RoomsConfiguration = (props: WidgetProps) => {
  const {
    slideState = {},
    setSlideState,
    payload,
    setPayload,
    setSlideDirection,
  } = props.formContext;
  const [rooms, setRooms] = useState(props.value);
  const [error, setError] = useState("");
  const selected = slideState.RoomsConfiguration?.selected ?? 0;
  const theme = useTheme();
  const roomCounts = {} as Record<string, number>;
  const { onboardingId, slideIndex } = props.formContext;
  let i = 0;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rooms.forEach((room: any) => {
    const type = room.type;
    if (!type) return;
    if (!roomCounts[type]) roomCounts[type] = 0;
    roomCounts[type] += 1;
    room.SvgIcon = ROOM_TYPE_ICONS[type];
    room.selected = selected === i;
    room.variant =
      room.name &&
      room.floor &&
      room.size &&
      room.walls &&
      room.wallsWithWindows
        ? "checked"
        : "default";
    (room.label =
      theme.config?.language?.[getLanguage(theme)]?.labels?.["rooms.type.enum"][
        type
      ] ?? type),
      (i += 1);
  });

  const room = props.value[selected];

  if (!room) {
    setSlideDirection(-1);
    return null;
  }

  let isFinished = true;
  if (rooms && rooms.length) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rooms.forEach((room: any) => {
      if (
        !(
          room.name &&
          room.floor &&
          room.size &&
          room.walls &&
          room.wallsWithWindows
        )
      ) {
        isFinished = false;
      }
    });
  }

  const numRooms = rooms?.length ?? 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setValue = (key: string, value: any) => {
    setSlideState({
      ...slideState,
      RoomsConfiguration: {
        ...slideState.RoomsConfiguration,
        [key]: value,
      },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setRoom = (key: string, value: any, index = selected) => {
    const newRooms = cloneDeep(props.value);
    newRooms[index][key] = value;
    setRooms([...newRooms]);
    props.onChange(newRooms);
    // setPayload({
    //   ...payload,
    //   rooms: newRooms,
    // });
  };

  const onButtonOptionClick = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    _option: any,
    index: React.SetStateAction<number>
  ) => {
    setValue("selected", index);
    setPayload({
      ...payload,
      rooms,
    });
  };

  return (
    <Stack justifyContent="center" alignItems="center" width="100%">
      <Stack
        px={[0, 2]}
        width="100%"
        maxWidth="900px"
        justifyContent="center"
        alignItems="center"
      >
        <Stack px={[2, 5]} py={2} borderRadius={2} spacing={3}>
          <RoundSvgButtonGroup
            values={rooms}
            error={error}
            onButtonClick={onButtonOptionClick}
          />
        </Stack>
        <Stack
          width={["100%"]}
          bgcolor="white"
          p={[2, 5]}
          borderRadius={2}
          spacing={3}
          border={error ? `1px solid ${theme.palette.error.main}` : undefined}
        >
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                height="100%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {String(
                    theme.config?.language?.[getLanguage(theme)]?.labels?.[
                      "rooms.name"
                    ]
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                defaultValue={room.name}
                sx={{ width: "100%" }}
                onChange={(event) => {
                  setRoom("name", event?.target?.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                height="100%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {String(
                    theme.config?.language?.[getLanguage(theme)]?.labels?.[
                      "rooms.floor"
                    ]
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup
                color="secondary"
                variant="outlined"
                aria-label={String(
                  theme.config?.language?.[getLanguage(theme)]?.labels?.[
                    "rooms.floor"
                  ]
                )}
                sx={{
                  ".MuiButtonGroup-firstButton": {
                    borderRadius: "10px 0 0 10px",
                  },
                  ".MuiButtonGroup-lastButton": {
                    borderRadius: "0 10px 10px 0",
                  },
                }}
                fullWidth
              >
                {payload.basement === "yes" && (
                  <StyledButton
                    field="floor"
                    value="basement"
                    room={room}
                    setRoom={setRoom}
                    theme={theme}
                  />
                )}
                {payload.aboveGroundStories >= 1 && (
                  <StyledButton
                    field="floor"
                    value="1"
                    room={room}
                    setRoom={setRoom}
                    theme={theme}
                  />
                )}
                {payload.aboveGroundStories >= 2 && (
                  <StyledButton
                    field="floor"
                    value="2"
                    room={room}
                    setRoom={setRoom}
                    theme={theme}
                  />
                )}
                {payload.aboveGroundStories >= 3 && (
                  <StyledButton
                    field="floor"
                    value="3"
                    room={room}
                    setRoom={setRoom}
                    theme={theme}
                  />
                )}
                {payload.attic === "yes" && (
                  <StyledButton
                    field="floor"
                    value="attic"
                    room={room}
                    setRoom={setRoom}
                    theme={theme}
                  />
                )}
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                height="100%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {String(
                    theme.config?.language?.[getLanguage(theme)]?.labels?.[
                      "rooms.size"
                    ]
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup
                variant="outlined"
                color="secondary"
                aria-label={String(
                  theme.config?.language?.[getLanguage(theme)]?.labels?.[
                    "rooms.floor"
                  ]
                )}
                sx={{
                  ".MuiButtonGroup-firstButton": {
                    borderRadius: "10px 0 0 10px",
                  },
                  ".MuiButtonGroup-lastButton": {
                    borderRadius: "0 10px 10px 0",
                  },
                }}
                fullWidth
              >
                <StyledButton
                  field="size"
                  value="SMALL"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="size"
                  value="MEDIUM"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="size"
                  value="LARGE"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="size"
                  value="XL"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="size"
                  value="XXL"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                height="100%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {String(
                    theme.config?.language?.[getLanguage(theme)]?.labels?.[
                      "rooms.walls"
                    ]
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup
                variant="outlined"
                color="secondary"
                aria-label={String(
                  theme.config?.language?.[getLanguage(theme)]?.labels?.[
                    "rooms.floor"
                  ]
                )}
                sx={{
                  ".MuiButtonGroup-firstButton": {
                    borderRadius: "10px 0 0 10px",
                  },
                  ".MuiButtonGroup-lastButton": {
                    borderRadius: "0 10px 10px 0",
                  },
                }}
                fullWidth
              >
                <StyledButton
                  field="walls"
                  value="0"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="walls"
                  value="1"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="walls"
                  value="2"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="walls"
                  value="3"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="walls"
                  value="4"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
              </ButtonGroup>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box
                display="flex"
                height="100%"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography>
                  {String(
                    theme.config?.language?.[getLanguage(theme)]?.labels?.[
                      "rooms.wallsWithWindows"
                    ]
                  )}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={8}>
              <ButtonGroup
                variant="outlined"
                color="secondary"
                aria-label={String(
                  theme.config?.language?.[getLanguage(theme)]?.labels?.[
                    "rooms.floor"
                  ]
                )}
                sx={{
                  ".MuiButtonGroup-firstButton": {
                    borderRadius: "10px 0 0 10px",
                  },
                  ".MuiButtonGroup-lastButton": {
                    borderRadius: "0 10px 10px 0",
                  },
                }}
                fullWidth
              >
                <StyledButton
                  field="wallsWithWindows"
                  value="none"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="wallsWithWindows"
                  value="just one"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="wallsWithWindows"
                  value="a few"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="wallsWithWindows"
                  value="a lot"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
                <StyledButton
                  field="wallsWithWindows"
                  value="huge ones"
                  room={room}
                  setRoom={setRoom}
                  theme={theme}
                />
              </ButtonGroup>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Stack>
        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}
        <Stack
          spacing={2}
          sx={{ mt: 4 }}
          direction="row"
          justifyContent="center"
        >
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => {
              const newPayload = {
                ...payload,
                rooms,
              };
              setPayload(newPayload);
              if (onboardingId) {
                api.put(`onboarding/${onboardingId}`, {
                  payload: newPayload,
                  activeStep: slideIndex,
                });
              }
              if (selected === 0) {
                setSlideDirection(-1);
              } else {
                setSlideState({
                  ...slideState,
                  RoomsConfiguration: {
                    ...RoomsConfiguration,
                    selected: selected - 1,
                  },
                });
              }
              window.scrollTo(0, 0);
            }}
            sx={{
              width: "150px",
            }}
          >
            Back
          </Button>
          {selected < numRooms - 1 ? (
            <Button
              variant="contained"
              color="secondary"
              data-cy="submit"
              data-key="RoomsConfigurationStep"
              onClick={() => {
                setSlideState({
                  ...slideState,
                  RoomsConfiguration: {
                    ...RoomsConfiguration,
                    selected: selected + 1,
                  },
                });
                const newPayload = {
                  ...payload,
                  rooms,
                };
                setPayload(newPayload);
                if (onboardingId) {
                  api.put(`onboarding/${onboardingId}`, {
                    payload: newPayload,
                    activeStep: slideIndex,
                  });
                }
                window.scrollTo(0, 0);
              }}
              sx={{ width: "150px" }}
            >
              Next
            </Button>
          ) : (
            <Button
              variant="contained"
              type={isFinished ? "submit" : "button"}
              data-cy="submit"
              data-key="RoomsConfigurationStep"
              onClick={() => {
                if (!isFinished) {
                  setError("Please finish all rooms!");
                }
              }}
              color="secondary"
              sx={{ width: "150px" }}
            >
              Next
            </Button>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default RoomsConfiguration;
