import React from "react";

export const Foyer = ({ stroke = "#222222" }) => {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.6416 26.3174H14.3852"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.48535 34.6184V21.2717H14.5416V34.6184"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.54492 3.85393C7.9381 2.60486 8.90712 1.72144 10.0402 1.72144C11.1744 1.72144 12.1442 2.60671 12.5366 3.85785"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3252 7.36658C10.5638 6.33438 10.9715 5.24487 11.577 4.63936C12.9055 3.3109 14.8387 3.0903 15.895 4.14661C16.9513 5.20296 16.7308 7.13621 15.4023 8.46466C14.7813 9.08564 13.6513 9.49855 12.5961 9.73444"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.1611 7.53271C17.4102 7.9259 18.2936 8.89493 18.2936 10.028C18.2936 11.1623 17.4083 12.132 16.1572 12.5244"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.537 10.2881C13.6016 10.5227 14.7486 10.9377 15.376 11.5651C16.7045 12.8936 16.925 14.8268 15.8687 15.8832C14.8124 16.9395 12.8791 16.7189 11.5507 15.3904C10.9359 14.7757 10.5252 13.662 10.2881 12.616"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4829 16.1492C12.0897 17.3982 11.1208 18.2816 9.98771 18.2816C8.85344 18.2816 7.8836 17.3964 7.49121 16.1453"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.70197 12.6347C9.46341 13.6675 9.05565 14.758 8.44974 15.3639C7.12128 16.6923 5.18803 16.9129 4.13168 15.8566C3.71508 15.44 3.49709 14.887 3.46739 14.2884C3.42175 13.3694 3.81995 12.343 4.62446 11.5385C5.2319 10.9311 6.3265 10.5227 7.36166 10.2844"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.86592 12.47C2.61684 12.0768 1.7334 11.1078 1.7334 9.97477C1.7334 8.8405 2.61869 7.87067 3.86978 7.47827"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.35249 9.68389C6.3279 9.44449 5.25134 9.03852 4.65081 8.43799C3.32235 7.10953 3.10179 5.1763 4.15809 4.11999C5.2144 3.06369 7.14766 3.28429 8.47612 4.61274C9.09433 5.23096 9.50634 6.35374 9.74273 7.40491"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0134 12.5246C11.4069 12.5246 12.5366 11.395 12.5366 10.0015C12.5366 8.60796 11.4069 7.47827 10.0134 7.47827C8.61989 7.47827 7.49023 8.60796 7.49023 10.0015C7.49023 11.395 8.61989 12.5246 10.0134 12.5246Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0137 18.2815V29.7663"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.0473 34.6186V22.2614C27.0473 21.5295 26.454 20.9363 25.7221 20.9363H20.8105C20.0787 20.9363 19.4854 21.5295 19.4854 22.2614V34.6186"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.2666 20.8063V16.5903"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.1133 15.6101C20.1133 17.3515 21.525 18.7632 23.2663 18.7632C25.0076 18.7632 26.4193 17.3515 26.4193 15.6101C26.4193 13.4307 23.2663 10.3174 23.2663 10.3174C23.2663 10.3174 20.1133 13.3699 20.1133 15.6101Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.976 34.6186V25.2255C37.976 24.4936 37.3827 23.9004 36.6508 23.9004H31.7392C31.0074 23.9004 30.4141 24.4936 30.4141 25.2255V34.6186"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.1953 23.7704V19.5544"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.042 18.5745C31.042 20.3158 32.4537 21.7275 34.195 21.7275C35.9363 21.7275 37.348 20.3158 37.348 18.5745C37.348 16.3951 34.195 13.2817 34.195 13.2817C34.195 13.2817 31.042 16.3342 31.042 18.5745Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.2667 34.8037H1.7334V40.279H40.2667V34.8037Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Foyer;
