import {
  Alert,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useState } from "react";
import KeyIcon from "@mui/icons-material/Key";
import logo from "@library/theme/multitenancy/pearl/logo.png";
import bg from "@library/theme/multitenancy/home-background.jpg";
import supabase from "@library/api/supabase";
import { useNavigate } from "react-router-dom";

const UpdatePasswordForm = () => {
  const [authError, setAuthError] = useState<string>();
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleOnSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const response = await supabase.auth.updateUser({ password });
    if (response?.error) {
      setAuthError(response?.error?.message ?? "An unknown error occured.");
    } else {
      navigate("/");
    }
  };

  const isError = Boolean(authError);

  return (
    <form onSubmit={handleOnSubmit}>
      <Stack spacing={4}>
        <Stack spacing={2}>
          {authError && <Alert severity="error">{authError}</Alert>}
          <>
            <TextField
              name="password"
              label="Password"
              defaultValue={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
              InputProps={{
                type: "password",
                error: isError,
              }}
            />
            <Button type="submit" variant="contained" data-testid="submit">
              Submit
            </Button>
          </>
        </Stack>
      </Stack>
    </form>
  );
};

export const UpdatePasswordPageView = () => {
  const theme = useTheme();
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      flexDirection="row"
      sx={{
        position: "absolute",
        top: 0,
        zIndex: 1100,
      }}
    >
      <Grid
        container
        spacing={0}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid
          item
          md={6}
          sm={0}
          xs={0}
          sx={{
            display: ["none", "none", "initial"],
            minHeight: "100vh",
            backgroundColor: "green",
            backgroundImage: `url(${bg})`,
          }}
        ></Grid>
        <Grid
          item
          md={6}
          sm={12}
          xs={12}
          alignItems="center"
          justifyContent="center"
          bgcolor="primary.main"
          sx={{ minHeight: "100vh" }}
        >
          <Stack justifyContent="center" alignItems="center" height="100vh">
            <img
              alt="Pearl Logo"
              src={logo}
              style={{
                width: "297px",
              }}
            />
            <Stack
              width={400}
              p={5}
              borderRadius={3}
              spacing={5}
              sx={{ backgroundColor: theme.palette.primary.contrastText }}
            >
              <Stack direction="row" spacing={2} alignItems="center">
                <KeyIcon />
                <Typography variant="h5">Reset Password</Typography>
              </Stack>
              <UpdatePasswordForm />
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UpdatePasswordPageView;
