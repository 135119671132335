import React from "react";
import { Alert, Box } from "@mui/material";

export const Header = () => {
  return (
    <Box m={2}>
      <Alert severity="info">
        Be sure to identify all rooms to ensure that your estimate is accurate.
      </Alert>
    </Box>
  );
};
